'use client';

import { Component, createContext } from 'react';

//* Helpers
import { pushDataLayer } from '@/helpers';

let iota = 0;
export const UIContext = createContext(null);

class UIProvider extends Component {
  //! States
  state = {
    winWidth: 1920,
    winHeight: 1080,
    screenSizes: {},
    preloader: false,
    highComponent: null,
    popupIsOpen: false,
    popupComponent: null,
    popupProps: {},
    isBurgerMenuOpen: false,
    notificationsList: [],
  };

  //! Methods
  methods = {
    addHighComponent: this.addHighComponent.bind(this),
    removeHighComponent: this.removeHighComponent.bind(this),
    disableDomScroll: this.disableDomScroll.bind(this),
    enableDomScroll: this.enableDomScroll.bind(this),
    openPopup: this.openPopup.bind(this),
    closePopup: this.closePopup.bind(this),
    togglePopup: this.togglePopup.bind(this),
    toggleBurgerMenu: this.toggleBurgerMenu.bind(this),
    closeBurgerMenu: this.closeBurgerMenu.bind(this),
    disablePreloader: this.disablePreloader.bind(this),
    showNotification: this.showNotification.bind(this),
    closeNotification: this.closeNotification.bind(this),
  };

  //! Screen Resize
  screenResize = () => {
    this.setState({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  //! Get Media Screen Sizes from Css Variables
  getScreenSizes() {
    const root = getComputedStyle(document.documentElement);

    const screenSizes = {
      desktopSizeXL: parseInt(root.getPropertyValue('--desktopSizeXL')),
      desktopSizeL: parseInt(root.getPropertyValue('--desktopSizeL')),
      desktopSizeM: parseInt(root.getPropertyValue('--desktopSizeM')),
      desktopSizeS: parseInt(root.getPropertyValue('--desktopSizeS')),
      tabletSize: parseInt(root.getPropertyValue('--tabletSize')),
      tabletSizeS: parseInt(root.getPropertyValue('--tabletSizeS')),
    };

    this.setState({ screenSizes });
  }

  //! Open Popup
  openPopup(popupComponent = null, popupProps = {}) {
    this.disableDomScroll();
    this.setState({
      popupIsOpen: true,
      popupComponent,
      popupProps,
    });
  }

  //! Close Popup
  closePopup() {
    this.enableDomScroll();
    if (this.state.popupProps?.closeEvent) {
      pushDataLayer({
        event: 'popupClose',
        popupTitle: this.state.popupProps.title,
      });
    }
    this.setState({
      popupIsOpen: false,
      popupComponent: null,
    });
  }

  //! Toggle Popup
  togglePopup() {
    this.setState({
      popupIsOpen: !this.state.popupIsOpen,
    });
  }

  //! Toggle Burger Menu
  toggleBurgerMenu() {
    if (this.state.isBurgerMenuOpen) {
      this.enableDomScroll();
    } else {
      this.disableDomScroll();
    }
    this.setState({ isBurgerMenuOpen: !this.state.isBurgerMenuOpen });
  }

  //! Close Burger Menu
  closeBurgerMenu() {
    this.enableDomScroll();
    this.state.isBurgerMenuOpen && this.setState({ isBurgerMenuOpen: false });
  }

  //! Disable Preloader
  disablePreloader() {
    this.setState({ preloader: false });
  }

  //! Disable DOM Scroll
  disableDomScroll() {
    document.querySelector('html').classList.add('hide-scroll');
    document.body.classList.add('hide-scroll');
  }

  //! Enable DOM Scroll
  enableDomScroll() {
    document.querySelector('html').classList.remove('hide-scroll');
    document.body.classList.remove('hide-scroll');
  }

  //! Add High Component
  addHighComponent(component = null) {
    this.setState({
      highComponent: component,
    });
  }

  updateHighComponentProps(props) {
    const Comp = this.state.component;
    Comp.props = props;

    this.setState({
      highComponent: Comp,
    });
  }

  //! Remove High Component
  removeHighComponent() {
    this.setState({
      highComponent: null,
    });
  }

  //! Component Did Mount
  componentDidMount() {
    this.setState({
      ...this.state,
    });

    window.addEventListener('resize', this.screenResize);
    this.getScreenSizes();
    this.screenResize();
  }

  //! Component Will Unmount
  componentWillUnmount() {
    window.addEventListener('resize', this.screenResize);
    document.removeEventListener('keydown', this.escKeydown, false);
  }

  showNotification({ type, title }) {
    this.setState({
      notificationsList: [
        ...this.state.notificationsList,
        {
          id: iota++,
          type,
          title,
        },
      ],
    });
  }

  closeNotification(id) {
    this.setState({
      notificationsList: this.state.notificationsList.filter(
        (item) => item.id !== id
      ),
    });
  }

  render() {
    return (
      <UIContext.Provider value={{ ...this.state, ...this.methods }}>
        {this.props.children}
      </UIContext.Provider>
    );
  }
}

export default UIProvider;
export const UIConsumer = UIContext.Consumer;
