import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/showroom-new/frontend/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/Page/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/Button/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/Image/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/Container/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/HX/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/components/global/Text/style.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["useActiveSection"] */ "/opt/showroom-new/frontend/src/helpers/_useActiveSection.js");
;
import(/* webpackMode: "eager", webpackExports: ["useResizeObserver"] */ "/opt/showroom-new/frontend/src/helpers/_useResizeObserver.js");
;
import(/* webpackMode: "eager" */ "/opt/showroom-new/frontend/src/modules/not-found/style.module.scss");
