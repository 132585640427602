import {
  CSSProperties,
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import cn from 'classnames';
import css from './style.module.scss';

type TextProps = PropsWithChildren & {
  className?: string;
  itemProp?: string;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  style?: CSSProperties;
};

const Text = forwardRef<HTMLParagraphElement, TextProps>((props, ref) => {
  return (
    <p
      itemProp={props.itemProp}
      ref={ref}
      className={cn(css.textComp, 'comp-text', props.className)}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      style={props.style}
    >
      {props.children}
    </p>
  );
});

Text.displayName = 'Text';

export default Text;
