'use client';
import { Fragment, useMemo, FC } from 'react';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';

import cn from 'classnames';

//* Components
import Text from '../Text';
import Image from '../Image';
import CustomLink from '../CustomLink';
import SubscribeWidget from './components/SubscribeWidget';
import { FAQBlock } from './components/FAQBlock';
import { RightWrapper } from './components/RightWrapper';

//* Style
import css from './style.module.scss';

type AppFooterProps = {};

const AppFooter: FC<AppFooterProps> = ({}) => {
  const pathname = usePathname();
  const t = useTranslations();

  const socialItems = useMemo(() => {
    return (
      <>
        <CustomLink
          className={css.socialIcon}
          external
          url={'https://www.linkedin.com/company/owner-one/'}
        >
          <Image src={'/images/svg/linkedin.svg'} alt={'linkedin-in-svg'} />
        </CustomLink>
        <CustomLink
          className={css.socialIcon}
          external
          url={'https://www.facebook.com/Owner.One.family/'}
        >
          <Image src={'/images/svg/facebook.svg'} alt={'facebook-in-svg'} />
        </CustomLink>
        <CustomLink
          className={css.socialIcon}
          external
          url={'https://twitter.com/OwnerOneFamily/'}
        >
          <Image src={'/images/svg/twitter.svg'} alt={'x-in-svg'} />
        </CustomLink>
      </>
    );
  }, []);

  const appDownloadItems = useMemo(() => {
    return (
      <Fragment>
        <div className={css.appLink}>
          <Image
            width={24}
            height={24}
            className={cn(css.contain, css.appLinkImage)}
            src={'/images/svg/gplay.svg'}
          />
        </div>
        <div className={css.appLink}>
          <Image
            width={24}
            height={24}
            className={cn(css.contain, css.appLinkImage)}
            src={'/images/svg/apple.svg'}
          />
        </div>

        <Text className={css.textStores}>{t('stores')}</Text>
      </Fragment>
    );
  }, []);

  return (
    <footer className={css.wrapper}>
      {pathname === '/' && <div className={css.shadow} />}
      <div className={css.topWrapper}>
        <div className={css.leftWrapper}>
          <Image className={css.logo} src="/images/new-logo.svg" />
          <FAQBlock />
        </div>
        <RightWrapper />
      </div>
      <div className={css.bottomWrapper}>
        <div className={css.isoRight}>
          <CustomLink url="/about-iso-14721" className={css.isoWrapper}>
            <Image
              height={100}
              width={100}
              className={css.iso_icon}
              alt="ISO 14721"
              src={'/images/svg/iso.svg'}
            />
            <Text className={css.text}>{t('isoCompliant')}</Text>
          </CustomLink>
          <div className={css.rights}>
            <Text className={css.text}>
              © {new Date().getFullYear()} Owner.One, {t('allRightsReserved')}
            </Text>
          </div>
        </div>
        <div className={css.rightCol}>
          <div className={css.formWrapper}>
            <SubscribeWidget />
          </div>
          <div className={css.socialsWrapper}>{socialItems}</div>
          <div className={css.appLinksWrapper}>{appDownloadItems}</div>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
