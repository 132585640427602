import cn from 'classnames';

//* Style
import css from './style.module.scss';
import { FC, PropsWithChildren } from 'react';

type PageProps = {
  className?: string;
  itemScope?: boolean;
  itemType?: string;
} & PropsWithChildren;

const Page: FC<PageProps> = ({ className, children, itemScope, itemType }) => {
  return (
    <main
      itemScope={itemScope}
      itemType={itemType}
      className={cn(css.page, [css.className], className)}
    >
      {children}
    </main>
  );
};

export default Page;
