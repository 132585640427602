'use client';
import { useEffect, useRef } from 'react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import cn from 'classnames';

//* Style
import css from './style.module.scss';

//* Components
import Page from '@/components/global/Page';
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import { HX } from '@/components/global/HX';
import Container from '@/components/global/Container';
import { VideoBlock } from './components/VideoBlock';
import { HowRepositoryWorks } from './components/HowRepositoryWorks';
import { Scenarios } from './components/Scenarios';

export default function HomeTemplate({}) {
  const t = useTranslations('homePage');

  const colRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  return (
    <Page className="home">
      <Container ref={containerRef} full className={css.wrapper}>
        <div className={css.content}>
          <div className={css.col}>
            <Image
              className={css.icon}
              src="/images/main/icon.svg"
              width={48}
              height={48}
              alt="icon"
            />
            <HX className={css.title}>{t('title')}</HX>
            <div>
              <Text className={css.text}>{t('description1')}</Text>
              <Text className={css.text}>{t('description2')}</Text>
            </div>
            <Button className={css.btn} btnType="link" url="/legend/">
              {t('goToLegend')}
            </Button>
          </div>
          <VideoBlock ref={colRef} />
        </div>
        <HX className={cn(css.title, css.titleMobile)}>{t('title')}</HX>
      </Container>
      <HowRepositoryWorks containerRef={containerRef} />
      <Scenarios />
      <Button className={css.legendBtn} btnType="link" url="/legend/">
        {t('goToLegend')}
      </Button>
    </Page>
  );
}
