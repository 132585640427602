'use client';
import {
  ChangeEventHandler,
  EventHandler,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import { Input } from 'antd';
import { useSearchParams } from 'next/navigation';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/all';
import { isEmailInvalid, pushDataLayer, ACTags, subscribe } from '@/helpers';
import Icon from '@/components/global/Icon';
import Text from '@/components/global/Text';
import { Spinner } from '@/components/global/Spinner';
import css from './style.module.scss';

gsap.registerPlugin(ScrollTrigger);

const SubscribeWidget = () => {
  const t = useTranslations();
  const ref = useRef<HTMLElement>(null);
  const searchParams = useSearchParams();
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useGSAP(
    () => {
      let tl: ReturnType<typeof gsap.timeline>;
      ScrollTrigger.create({
        trigger: ref.current,
        onEnter: () => {
          tl = gsap
            .timeline({
              repeat: -1,
              repeatDelay: 5,
            })
            .fromTo(
              ref.current,
              {
                rotation: '0deg',
              },
              { rotation: '30deg', duration: 0.2 }
            )
            .fromTo(
              ref.current,
              {
                rotation: '30deg',
              },
              { rotation: '-30deg', duration: 0.4 }
            )
            .fromTo(
              ref.current,
              {
                rotation: '-30deg',
              },
              { rotation: '30deg', duration: 0.4 }
            )
            .fromTo(
              ref.current,
              {
                rotation: '30deg',
              },
              { rotation: '0deg', duration: 0.2 }
            );
        },
        onLeaveBack: () => {
          tl.kill();
          gsap.to(ref.current, {
            rotation: '0deg',
            duration: 0.2,
          });
        },
      });
    },
    { dependencies: [ref.current] }
  );

  const onChange: ChangeEventHandler = (e) => {
    setEmail((e.target as HTMLInputElement).value);
    setIsError(false);
  };

  const submit: EventHandler<SyntheticEvent> = async (e) => {
    pushDataLayer({
      event: 'footer_subscribe_attempt',
      element: e.currentTarget,
      target: e.target,
    });
    if (email.length) {
      const err = isEmailInvalid(email);
      setIsError(err);
      if (!err) {
        setIsLoading(true);
        await subscribe(email, ACTags.FROM_FOOTER, searchParams);
        setIsLoading(false);
        setIsSucceed(true);
        pushDataLayer({
          event: 'footer_subscribe_success',
          email,
          target: e.target,
          element: e.currentTarget || e.target,
        });
      }
    }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.subscribeTitleWrapper}>
        <Icon name={'bell'} className={cn(css.icon, css.bell)} ref={ref} />
        <Text className={'font-avenir-regular'}>
          {t('subscribeForInsights')}
        </Text>
      </div>

      <Input
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submit(e);
          }
        }}
        onChange={onChange}
        value={isSucceed ? t('subscribeText') : email}
        placeholder={t('enterYourEmail')}
        name="email"
        type="email"
        className={cn(css.subscribeInput, 'font-avenir-regular', {
          [css.error]: isError,
          [css.succeed]: isSucceed,
        })}
        readOnly={isSucceed}
        suffix={
          !isSucceed ? (
            !isLoading ? (
              <Icon
                name={`arrow-short`}
                className={cn(css.icon, {
                  [css.active]: email.length && !isError,
                })}
                onClick={submit}
              />
            ) : (
              <Spinner className={css.spinner} />
            )
          ) : (
            <Icon name={`check`} className={(css.icon, css.checkIcon)} />
          )
        }
      />
      {isError && (
        <Text className={cn(css.errorMessage, 'font-avenir-regular')}>
          {t('emailInvalid')}
        </Text>
      )}
    </div>
  );
};

export default SubscribeWidget;
