'use client';

import { FC, useEffect, useState } from 'react';
import { withUIContext } from '@/context';
import cn from 'classnames';
import Text from '../Text';
import Icon from '../Icon';
import css from './styles.module.scss';

const CLOSE_TIMEOUT = 5000;

type Props = {
  id: number;
  type: string;
  title: string;
  closeNotification: (id: number) => void;
};

const NotificationItem: FC<Props> = ({
  id,
  type = 'success',
  title,
  closeNotification,
}) => {
  const [isClosing, setIsClosing] = useState(false);
  let timer: ReturnType<typeof setTimeout>;
  const close = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeNotification(id);
    }, 300);
  };
  useEffect(() => {
    timer = setTimeout(close, CLOSE_TIMEOUT);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      key={id}
      className={cn(css.notification, css[type], {
        [css.isClosing]: isClosing,
      })}
    >
      <div className={css.iconWrapper}>
        <Icon name={'info'} className={css.icon} />
      </div>
      <Text className={cn(css.title, 'font-avenir-regular')}>{title}</Text>
      <Icon name="close" onClick={close} className={cn(css.close, css.icon)} />
    </div>
  );
};

export default withUIContext<Props>(NotificationItem, ['closeNotification']);
