import { ReadonlyURLSearchParams } from 'next/navigation';
import { ACTags } from './types';
import { api } from '.';

export const stringToLetters = (text: string) => {
  let wordLength = text.split(' ');

  return wordLength.map((i, k) => {
    return (
      <span key={k} className="word">
        {i}
      </span>
    );
  });
};

export const encodeTitle = (str: string) => {
  return encodeURI(
    str
      .toLowerCase()
      .replace(/[^\w\s]/g, '')
      .replace(/\s+/g, '-')
  );
};

export const getByString = (path: string, obj: any) => {
  return path?.split('.').reduce((p, c) => (p && p[c]) || null, obj);
};

const formatter = new Intl.NumberFormat('ru', {
  style: 'decimal',
});

export const formatNumbers = (n: number) => {
  return formatter.format(n);
};

export const throttle = <T,>(fn: (...args: T[]) => void, wait: number) => {
  let time = Date.now();
  return function (...args: T[]) {
    if (time + wait - Date.now() < 0) {
      fn(...args);
      time = Date.now();
    }
  };
};

export const getUtmObject = (searchParams: ReadonlyURLSearchParams) => {
  return {
    utm_campaign: searchParams.get('utm_campaign'),
    utm_medium: searchParams.get('utm_medium'),
    utm_source: searchParams.get('utm_source'),
    utm_content: searchParams.get('utm_content'),
    utm_term: searchParams.get('utm_term'),
  };
};

export const preapreUtmFields = () => {
  const firstTouch = JSON.parse(localStorage.getItem('firstTouch') || 'null');
  const lastTouch = JSON.parse(localStorage.getItem('lastTouch') || 'null');
  const subscription = JSON.parse(
    sessionStorage.getItem('subscription') || 'null'
  );

  const obj = {};

  if (subscription) {
    Object.assign(obj, {
      subscription_campaign: subscription.utm_campaign,
      subscription_medium: subscription.utm_medium,
      subscription_source: subscription.utm_source,
      subscription_content: subscription.utm_content,
      subscription_term: subscription.utm_term,
    });
  }

  if (firstTouch) {
    Object.assign(obj, {
      first_touch_campaign: firstTouch.utm_campaign,
      first_touch_medium: firstTouch.utm_medium,
      first_touch_source: firstTouch.utm_source,
      first_touch_content: firstTouch.utm_content,
      first_touch_term: firstTouch.utm_term,
    });
  }

  if (lastTouch) {
    Object.assign(obj, {
      last_touch_campaign: lastTouch.utm_campaign,
      last_touch_medium: lastTouch.utm_medium,
      last_touch_source: lastTouch.utm_source,
      last_touch_content: lastTouch.utm_content,
      last_touch_term: lastTouch.utm_term,
    });
  }

  return obj;
};

export const getUtmFull = (searchParams: ReadonlyURLSearchParams) => {
  const obj = getUtmObject(searchParams);
  const params = new URLSearchParams();

  Object.entries(obj).forEach(([name, value]) => {
    if (value) {
      params.append(name, value);
    }
  });

  return params.toString();
};

export const subscribe = async (
  email: string,
  tag: ACTags,
  searchParams: ReadonlyURLSearchParams
) => {
  const obj = {
    email,
    tag,
    utmFull: getUtmFull(searchParams),
    ...preapreUtmFields(),
  };

  return await api.post('/subscribe', obj);
};
