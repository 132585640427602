'use client';

import React, { FC, RefObject, useState } from 'react';
// import YouTube, { YouTubeProps } from 'react-youtube';
import ReactPlayer from 'react-player/youtube';
// import ReactPlayer from 'react-player';

import { getDefaultLanguage } from '@/utils/languages';

import { getVideoIdByLanguage } from './helpers';
import { SelectLanguage } from '../SelectLanguage';

import css from './videoblock.module.scss';
import { useTranslations } from 'next-intl';

type VideoBlockProps = {
  ref: RefObject<HTMLDivElement | null>;
};

export const VideoBlock: FC<VideoBlockProps> = ({ ref }) => {
  const t = useTranslations('homePage');
  const [language, setLanguage] = useState(
    typeof window !== 'undefined' ? getDefaultLanguage() : 'en'
  );

  const videoId = getVideoIdByLanguage(language);

  const handleChange = (value: string) => {
    setLanguage(value);
  };

  return (
    <div className={css.container} ref={ref}>
      <SelectLanguage value={language} onChange={handleChange} />
      <div className={css.videowrap}>
        <PlayerReactPlayer videoId={videoId} />
      </div>
    </div>
  );
};

type PlayerReactPlayerProps = { videoId: string };
const PlayerReactPlayer: FC<PlayerReactPlayerProps> = ({ videoId }) => {
  return (
    <ReactPlayer
      width={'auto'}
      height={'100%'}
      url={`https://www.youtube.com/watch?v=${videoId}`}
      controls={true}
      loop={true}
    />
  );
};
