import { forwardRef, PropsWithChildren, MouseEventHandler } from 'react';
import cn from 'classnames';

type IconProps = PropsWithChildren & {
  name: string;
  className?: string;
  onClick?: MouseEventHandler;
};

const Icon = forwardRef<HTMLElement, IconProps>(
  ({ name, className, ...props }, ref) => {
    return (
      <i
        ref={ref}
        onClick={props.onClick}
        className={cn(`oo-${name}`, 'icon', className)}
      >
        {props.children}
      </i>
    );
  }
);

Icon.displayName = 'Icon';

export default Icon;
