import { ContentType } from '../types';
import { DOCUMENTS } from './documents';

const imageSrc = '/images/toolkit/content';
export const TOOLKIT_DATA: ContentType[] = [
  {
    ordering: 1,
    title: 'Assets Data Repositories And Wealth Transfer',
    description:
      'Review the Presentation about the Assets Data Repository to gain a detailed understanding of its functionality and benefits',
    type: 'doc',
    image: imageSrc + '1' + '.webp',
    files: DOCUMENTS.assetsDataRepository,
  },
  {
    ordering: 2,
    title: 'Family guide weathering the storm',
    description:
      'The Guide compile opinions and life hacks shared by our clients. These practices helped wealthy families with assets of up to $100 million in preserving and transferring their capital among family members',
    type: 'doc',
    image: imageSrc + '2' + '.webp',
    files: DOCUMENTS.familyGuide,
  },
  {
    ordering: 3,
    title: 'Past and Present: Preventing Capital Loss in Transfers',
    description:
      'Explore how Owner.One, the first assets data repository, addresses common capital transfer issues and changes the state of affairs',
    type: 'link',
    image: imageSrc + '3' + '.webp',
    link: '/before-and-now/',
  },
  {
    ordering: 4,
    title: 'Penguin Analytics',
    description:
      'The global research about risks of assets and capital preservation and transfer. Based on the survey of 13 500 families with $3M to $99M in assets from 18 countries',
    type: 'doc',
    image: imageSrc + '4' + '.webp',
    files: DOCUMENTS.analytics,
  },
  {
    ordering: 5,
    title: 'Penguin Analytics shortcuts',
    description:
      'An executive summary of global research on the risks associated with asset preservation, capital protection, and transfer',
    type: 'doc',
    image: imageSrc + '5' + '.webp',
    files: DOCUMENTS.shortcuts,
  },
  {
    ordering: 7,
    title: 'Capital Founders Global Survey',
    description:
      'Take part in the Owner.One global research survey on risks in assets and capital preservation and transfer',
    type: 'link',
    image: imageSrc + '7' + '.webp',
    link: 'https://ownerone.surveysparrow.com/s/2304-Founders/tt-cprTzL3oTvttuH46TSv342',
  },
  {
    ordering: 8,
    title: "Global Survey for Capital Founders' Spouses",
    description:
      'Take part in the Owner.One global research survey on risks in assets and capital preservation and transfer',
    type: 'link',
    image: imageSrc + '8' + '.webp',
    link: 'https://ownerone.surveysparrow.com/s/1104-For-Wives/tt-6QUFQHn9PMBv856kLDd1hk?survey_name=for_wives',
  },
  {
    ordering: 9,
    title: "Global Survey for Capital Founders' Kids ",
    description:
      'Take part in the Owner.One global research survey on risks in assets and capital preservation and transfer',
    type: 'link',
    image: imageSrc + '9' + '.webp',
    link: 'https://ownerone.surveysparrow.com/s/2404-For-Jun/tt-gFSBeWoJaoYAQERKLL3RCq?survey_name=for_jun',
  },
  {
    ordering: 0,
    title: 'Crash Test For Family Capital',
    description:
      'What happens if you can no longer act? In 81.63% of cases, your family won’t have all the data needed to fully take over assets. Crash-test how much they’ll manage to preserve',
    type: 'link',
    image: imageSrc + '10.webp',
    link: '/toolkit/crash-test-for-family-capital',
  },
].sort((a, b) => a.ordering - b.ordering) as ContentType[];
