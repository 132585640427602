'use client';
import { MouseEventHandler, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import Text from '@/components/global/Text';
import CustomLink from '@/components/global/CustomLink';
import { config, pushDataLayer } from '@/helpers';
import css from '../../style.module.scss';

export const RightWrapper = () => {
  const t = useTranslations();
  const getFooterRoutes = useCallback(
    (column: string) => {
      return Object.entries(config.routes).map((route, ind) => {
        if (route[1].isFooter === column) {
          return (
            <Text key={ind} className={css.text}>
              <CustomLink url={route[1].path} className={css.link}>
                {route[1].name}
              </CustomLink>
            </Text>
          );
        }
      });
    },
    [config.routes]
  );

  const productItems = useMemo(() => getFooterRoutes('col-1'), []);
  const resourcesItems = useMemo(() => getFooterRoutes('col-2'), []);
  const legalItems = useMemo(() => getFooterRoutes('col-3'), []);

  const GAInternalLinkClick: MouseEventHandler = (e) => {
    if ((e.target as HTMLElement).nodeName !== 'A') return;
    pushDataLayer({
      event: 'footer_internal_link_click',
      element: e.currentTarget,
      target: e.target,
    });
  };

  return (
    <div className={css.rightWrapper} onClick={GAInternalLinkClick}>
      <div className={css.column}>
        <Text className={cn(css.title, 'capitalize')}>{t('product')}</Text>
        <div className={css.items}>{productItems}</div>
      </div>

      <div className={css.column}>
        <Text className={cn(css.title, 'capitalize')}>{t('recourses')}</Text>
        <div className={css.items}>{resourcesItems}</div>
      </div>

      <div className={css.column}>
        <Text className={cn(css.title, 'capitalize')}>{t('legal')}</Text>
        <div className={css.items}>{legalItems}</div>
      </div>
    </div>
  );
};
