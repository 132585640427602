'use client';

import { withUIContext } from '@/context';
import NotificationItem from './notification-item';
import css from './styles.module.scss';

type Props = {
  notificationsList: { id: number; type: string; title: string }[];
};

export default withUIContext<Props>(
  ({ notificationsList }) => {
    return (
      <div className={css.wrapper}>
        {notificationsList.map((item) => (
          <NotificationItem
            id={item.id}
            type={item.type}
            title={item.title}
            key={item.id}
          />
        ))}
      </div>
    );
  },
  ['notificationsList']
);
