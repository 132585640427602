import { memo, MouseEventHandler, ReactNode, useMemo } from 'react';

//* Components
import Icon from '../Icon';
import CustomLink from '../CustomLink';
import cn from 'classnames';
//* Style
import css from './style.module.scss';

type ButtonProps = {
  btnType?: 'link';
  variant?: 'primary' | 'secondary' | 'outline' | 'territory' | 'destruction';
  className?: string;
  onClick?: MouseEventHandler;
  url?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  children?: ReactNode;
  external?: boolean;
};

const Button = memo<ButtonProps>(
  ({
    btnType,
    className,
    onClick,
    url,
    type = 'button',
    disabled = false,
    children,
    external,
    variant = 'primary',
  }) => {
    const Component = useMemo(() => (url ? CustomLink : 'button'), [url]);

    const customProps = useMemo(() => {
      if (url) {
        return {
          url,
          external,
        };
      } else {
        return { type, disabled, url: '' };
      }
    }, [url, external, type, disabled]);

    let btn;

    switch (btnType) {
      case 'link':
        btn = (
          <div
            onClick={onClick}
            className={cn(
              css.wrapper,
              css.btnText,
              css.btnLink,
              className,
              css[variant]
            )}
          >
            <Component {...customProps}>
              <span>{children}</span>
              <Icon className={css.icon} name={'arrow-short'} />
            </Component>
          </div>
        );
        break;

      default:
        btn = (
          <div
            onClick={onClick}
            className={cn(css.wrapper, css.btnText, className, css[variant])}
          >
            <Component {...customProps}>{children}</Component>
          </div>
        );
    }

    return btn;
  }
);

export default Button;
