'use client';

import { pushDataLayer, throttle } from '@/helpers';
import { PropsWithChildren, useEffect } from 'react';

function ScrollWrapper({ children }: PropsWithChildren) {
  useEffect(() => {
    const fn = throttle((_) => {
      pushDataLayer({
        event: 'page_scroll',
      });
    }, 500);
    addEventListener('scroll', fn);

    return () => {
      removeEventListener('scroll', fn);
    };
  }, []);

  return children;
}

export default ScrollWrapper;
