import { RoutesItem } from './types';
import { TOOLKIT_DATA } from './consts/toolkit';
import { VISIBLE_BLOG_POSTS } from './consts/blog';
import { POSTS } from './consts/yansnotes';
import { encodeTitle } from './_functions';

const routes: Record<string, RoutesItem> = {
  home: {
    path: '/',
    slug: 'home',
    name: 'home',
  },

  //! Recourses
  legend: {
    path: '/legend/',
    slug: 'legend',
    name: 'Legend',
    isFooter: 'col-1',
    isHeader: true,
  },

  // Added like in design
  yansnotes: {
    path: '/yansnotes/',
    name: 'Yan’s Notes',
    isHeader: false,
    isFooter: 'col-2',
  },

  analytics: {
    path: '/analytics/',
    slug: 'analytics',
    name: 'Analytics',
    isFooter: 'col-2',
    isHeader: true,
  },

  product: {
    path: '/product/',
    slug: 'product',
    name: 'How it works',
    isFooter: 'col-1',
    isHeader: true,
  },

  aboutUs: {
    path: '/about-us/',
    slug: 'about-us',
    name: 'About Us',
    isFooter: 'col-1',
    isHeader: true,
  },

  toolkit: {
    path: '/toolkit/',
    slug: 'toolkit',
    name: 'Toolkit',
    isHeader: true,
    isFooter: 'col-2',
  },

  pricing: {
    path: '/start-using/',
    slug: 'pricing',
    name: 'Start using',
    isFooter: 'col-1',
    isHeader: true,
  },

  blog: {
    path: '/blog/',
    slug: '/blog/:slug',
    name: 'Blog',
    isFooter: 'col-2',
    isHeader: false,
  },

  //! Legal
  privacyPolicy: {
    path: '/privacy-policy/',
    slug: 'privacy-policy',
    name: 'Privacy Policy',
    isFooter: 'col-3',
  },

  cookiesPolicy: {
    path: '/cookies-policy/',
    slug: 'cookies-policy',
    name: 'Cookies Policy',
    isFooter: 'col-3',
  },

  termsOfService: {
    path: '/terms-of-service/',
    slug: 'terms-of-service',
    name: 'Terms Of Service',
    isFooter: 'col-3',
  },

  contacts: {
    path: '/contacts/',
    slug: 'contacts',
    name: 'Contacts',
    isFooter: 'col-3',
  },

  beforeAndNow: {
    path: '/before-and-now/',
    slug: 'before-and-now',
    name: 'Before and now',
  },

  faq: {
    path: '/faq/',
    slug: 'faq',
    name: 'FAQ',
  },

  quiz: {
    path: '/quiz/',
    slug: 'quiz',
    name: 'Quiz',
  },
};

TOOLKIT_DATA.forEach((item) => {
  if (!item.link || !item.link.startsWith('/')) return;

  routes[item.title] = {
    path: item.link,
    slug: item.link,
    name: item.title,
  };
});

VISIBLE_BLOG_POSTS.forEach((item) => {
  routes[item.title] = {
    path: `/blog/${item.slug}/`,
    slug: item.slug,
    name: item.title,
  };
});

POSTS.forEach((item) => {
  const slug = encodeTitle(item.title);
  routes[item.title] = {
    path: `/yansnotes/${slug}/`,
    slug: slug,
    name: item.title,
  };
});

export default {
  routes,
};
