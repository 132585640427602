import { useTranslations } from 'use-intl';
import Container from '@/components/global/Container';
import { HX } from '@/components/global/HX';
import Icon from '@/components/global/Icon';
import Text from '@/components/global/Text';
import css from './style.module.scss';

export const Scenarios = () => {
  const t = useTranslations('homePage.scenarios');
  return (
    <Container className={css.wrapper}>
      <HX className={css.title} level="h2">
        {t('title')}
      </HX>
      <div className={css.row}>
        <div className={css.scenario}>
          <Text className={css.scenarioTitle}>{t('scenario1.title')}</Text>
          <Text className={css.scenarioSubtitle}>
            {t('scenario1.subtitle')}
          </Text>
          <Text className={css.description}>{t('scenario1.description')}</Text>
          <div className={css.seconds}>
            <Icon className={css.icon} name="timer" />
            <Text className={css.secondsText}>{t('seconds')}</Text>
          </div>
        </div>
        <div className={css.scenario}>
          <Text className={css.scenarioTitle}>{t('scenario2.title')}</Text>
          <Text className={css.scenarioSubtitle}>
            {t('scenario2.subtitle')}
          </Text>
          <Text className={css.description}>{t('scenario2.description')}</Text>
          <div className={css.seconds}>
            <Icon className={css.icon} name="timer" />
            <Text className={css.secondsText}>{t('seconds')}</Text>
          </div>
        </div>
      </div>
    </Container>
  );
};
