import { forwardRef } from 'react';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import cn from 'classnames';
import { POSTS_LENGTH } from '@/helpers/consts/yansnotes';
import Icon from '../../Icon';
import css from './index.module.scss';

export const YNButton = forwardRef<HTMLAnchorElement, { isActive: boolean }>(
  ({ isActive }, ref) => {
    const t = useTranslations();
    return (
      <Link
        href={'/yansnotes/'}
        ref={ref}
        className={cn(
          css.wrapper,
          'yn_button',
          'navigationLink',
          'burgerMenuLink',
          {
            active: isActive,
          }
        )}
      >
        <span className={css.text}>{t('publicTalk')}</span>
        <Icon name="note" className={css.icon} />
        <span className={css.counter}>{POSTS_LENGTH}</span>
      </Link>
    );
  }
);
