import { BlockTypes, BlogItemLong, ContentBlock } from '../types';

const text = (text: string) =>
  ({
    text,
    type: BlockTypes.TEXT,
  } as ContentBlock);

const h2 = (text: string) =>
  ({
    text,
    type: BlockTypes.SUBHEADER,
  } as ContentBlock);

const h3Italic = (text: string) =>
  ({
    text,
    type: BlockTypes.SMALL_HEADER_ITALIC,
  } as ContentBlock);

const h3 = (text: string) =>
  ({
    text,
    type: BlockTypes.SMALL_HEADER,
  } as ContentBlock);

const h3Light = (text: string) =>
  ({
    text,
    type: BlockTypes.LIGHT_HEADER,
  } as ContentBlock);

const link = (text: string, url: string) =>
  ({
    text,
    url,
    type: BlockTypes.LINK,
  } as ContentBlock);

const bold = (text: string) =>
  ({
    text: text,
    type: BlockTypes.BOLD,
  } as ContentBlock);

const italic = (text: string) =>
  ({
    text: text,
    type: BlockTypes.ITALIC,
  } as ContentBlock);

const list = (content: ContentBlock[]) =>
  ({
    content,
    type: BlockTypes.LIST,
  } as ContentBlock);

const listNumeric = (content: ContentBlock[]) =>
  ({
    content,
    type: BlockTypes.LIST_NUMERIC,
  } as ContentBlock);

const block = (content: ContentBlock[]) =>
  ({
    type: BlockTypes.BLOCK,
    content,
  } as ContentBlock);

const image = (url: string, alt: string) =>
  ({
    type: BlockTypes.IMAGE,
    url,
    alt,
  } as ContentBlock);

// localization isn't planned yet
export const BLOG_POSTS: BlogItemLong[] = [
  {
    id: 7,
    title: 'Tax Savings Strategies for Intra-Family Wealth Transfers',
    description:
      'How to transfer wealth without paying taxes? An eye-catching, promising, yet misleading question. However, there are time-tested ways to offload part of the burden. This article provides actionable insights to be examined by capital founders.',
    contentImage: '/images/blog/tax_preview.png',
    image: '/images/blog/tax_preview.png',
    imageAlt:
      'A balanced scale with money on one side and real estate on the other, symbolizing the balance between financial assets and property in wealth transfer planning.',
    slug: 'tax-savings-strategies-for-intra-family-wealth-transfers',
    meta: {
      title: 'Tax Savings Strategies for Intra-Family Wealth Transfers',
      desription:
        'Discover effective tax-saving strategies for intra-family wealth transfers. Learn how to minimize tax obligations through trusts, exemptions, and legal structures. Prepare for generational transfers and protect your legacy.',
      keywords:
        'tax savings, wealth transfer, intra-family transfers, tax-efficient strategies, trusts, estate planning, asset protection, generational wealth, high-net-worth individuals',
      og: {
        title: 'Tax Savings Strategies for Intra-Family Wealth Transfers',
        desription:
          'Discover tax-saving strategies for intra-family wealth transfers. Learn how to use trusts, exemptions, and legal structures to protect your assets.',
        image: '/images/blog/tax_preview.png',
      },
    },
    content: [
      text(
        'How to transfer wealth without paying taxes? An eye-catching, promising, yet misleading question. However, there are time-tested ways to offload part of the burden. This article provides actionable insights to be examined by capital founders.'
      ),
      h2('KEY TAKEAWAYS'),
      list([
        text(
          'Owners of $5m-$30m will constitute an 87% share of all affluent individuals involved in intergenerational transfers.'
        ),
        text(
          'Some countries offer exemptions. This presents an opportunity to lessen some of the compulsory levies. '
        ),
        text(
          'Often, high earners use special legal entities for estate planning.'
        ),
      ]),
      block([
        text('According to '),
        link(
          'Altrata’s',
          'https://altrata.com/reports/family-wealth-transfer-2024'
        ),
        text(
          ' report, by 2033 the staggering collective net worth of $31 trillion will be passed on from wealth founders to their relatives and other individuals. The outcome will define the standards of living of all high-income families in the upcoming 10 years. '
        ),
      ]),
      text(
        'Remarkably, high-net-worth individuals with $5-30 million in assets represent an 87% share of all those transferring wealth to succeeding generations.'
      ),
      text(
        'At first glance, it presents a mouth-watering chance for the potential beneficiaries. However, they rarely think of the bruising effects of the tax regulations at this stage. Without early engagement and preparation, they could face mandatory tax payments of up to 40%.'
      ),
      text(
        'Globalized citizens are the most vulnerable to these risks. Generally, their business transactions span multiple jurisdictions. This subjects them to more complex laws.'
      ),
      text(
        'What multi-pronged strategies could safeguard holdings and relatives?'
      ),
      h2('Preventable and inevitable financial consequences'),
      text(
        "The primary goal is to avoid a trade-off between the family's future and the influence of taxation. Planning ahead will reduce unnecessary perils."
      ),
      text(
        "Jurisdictions offer exemptions. For example, this refers to lifetime estate tax. The exact amount is a condition of specific countries' regulations. In some of them, it is equivalent to more than $13 million worth of assets for tax-free transfer."
      ),
      text(
        'What If you are to hand over possessions valued at more than the abovementioned sum? The applicable levy rate may reach as far as 40%. Debts, mortgages, or property maintenance costs can be kept out of this payment. '
      ),
      text(
        'What if it’s simply a transfer from one spouse to another? This qualifies for a tax-free option as well. However, the subsequent step, when transferring assets to children, unavoidably implies fiscal obligations.'
      ),
      h2('Exploring capabilities of special legal formations'),
      text(
        'U/HNWIs frequently create dedicated legal structures. They transfer ownership of their assets to these entities. Irrevocable and revocable trusts are the most common options. If so, the founders act as the grantors of these organizations.'
      ),
      text(
        'How does this assist in arranging family wealth transfers in a tax-effective manner? For instance, it lowers your taxable estate level. The entire value of the funds held in trust is not included in this assessment.'
      ),
      text(
        'The same applies to all earnings of the trust. As a rule of thumb, they are subject to the legal entity taxation and not the donor.'
      ),
      text(
        'What is the reverse side of the coin? The grantor has no power to alter or shut down the trust. Once it has been set, the donor has no right to reclaim assets and capital from it. However, this issue is relevant only for irrevocable trusts.'
      ),
      text(
        'Is it a problem for you? If so, consider other entities like revocable trusts. They enable the grantor to take back the funds, chattels, and other valuables. '
      ),
      h2('Philanthropy and other types of giving'),
      text(
        'Is gifting a simpler and more straightforward tactic? To some extent, it is. However, there are policies for it likewise. '
      ),
      text(
        'The typical approach is to opt for an annual gift tax exclusion. In some countries, the range varies from $15 000 to $18 000 per person annually. All capital worth up to this rate can be gifted on a tax-free basis. Regulators typically adjust the rate for yearly inflation levels.'
      ),
      text(
        'What if the worth of the money, properties, collectibles, or other assets exceeds this grade? If the total worth of these assets is below the rate applicable to a lifetime exemption, they can be classified as non-taxable. '
      ),
      text(
        'Notably, contributions made to charities or spouses are also non-taxable. The same goes for healthcare and educational institutions donations. If these gifts are to other beneficiaries than the donor, they may qualify for tax-free status.'
      ),
      h2('What is FLP?'),
      text(
        'Another tax-effective method is forming a family limited partnership (FLP). Under the FLP, family members serve as general or limited partners. They take responsibility for controlling assets and investments. '
      ),
      text(
        'This legal structure targets gift and estate tax liabilities. However, legislative frameworks imply that the FLP must be an income-generating organization. Consequently, all involved in its operations are obliged to report any profits obtained through it.'
      ),
      h2('What is the generational wealth transfer?'),
      text(
        'This term refers to the unraveling process of passing on fortunes from capital founders to their families. It is estimated that $31 trillion will be allocated to the next generation by 2033.'
      ),
      block([
        text('As per '),
        link(
          'Altrata’s',
          'https://altrata.com/reports/family-wealth-transfer-2024'
        ),
        text(
          ' report, 87% of all wealth owners in the process have net worths ranging from $5m to $30m.'
        ),
      ]),
      text(
        'According to Owner.One’s research, this category accounts for up to 75% of all losses that occur during intra-family handovers.'
      ),

      h2('What is the most tax-efficient strategy?'),
      text(
        'Unfortunately, there is no silver bullet. This process largely depends on tax regulations applicable to the wealth owner’s countries of residence, business activities, or asset location.'
      ),
      h2('What is the best legal structure to minimize tax burden?'),
      text(
        "Again, there's no one-size-fits-all solution. In general, the main idea is to differentiate tax liability. For example, if income is generated by the trust, the donor of the trust is not subject to taxation."
      ),
      h2('What qualifies for tax-free options?'),
      text(
        'In particular, gifts to spouses or charities.  This is also true for direct contributions to healthcare and educational institutions. '
      ),
      h2('CONCLUSION'),
      text(
        'Undoubtedly, wealth transfer planning is a formidable duty. It challenges heads of families to distribute their fortunes fairly. The concealed task is to make certain that hard-won assets will last across generations.'
      ),
      text(
        'Part of the job is to align wealth transfer strategies with tax regulations in advance. '
      ),
      text(
        'The other mission is to make sure that your wealth data is in transfer-ready mode. On average, $340 000 per $1 000 000 is lost due to the unpreparedness of capital founders and their families. '
      ),
      text(
        'Owner.One is the only comprehensive digital infrastructure addressing these challenges. You may try it out now and see for yourself.'
      ),
      h2('Disclaimer'),
      text(
        'The provided content is intended for informational purposes only and should not be construed as investment or tax advice. We advise all readers to conduct their own research before making any investment or tax decisions.'
      ),
      text(
        'While Owner.One endeavors to keep the information up-to-date and correct, we make no representations or warranties, express or implied, regarding the completeness, accuracy, reliability, suitability, or availability of the information for any purpose. Any reliance you place on such information is strictly at your own risk.'
      ),
    ],
  },
  {
    id: 1,
    image: '/images/blog/great_wealth.png',
    contentImage: '/images/blog/great_wealth.png',
    imageAlt: 'The Great Wealth Transfer',
    title:
      'The Great Wealth Transfer: Risks, Challenges, and Opportunities for Asset Preservation',
    description:
      'The great intergenerational wealth transfer has already begun. It will be the largest in history. Experts expect families and others to receive more than $72.6 trillion from capital founders by 2045. What are the key risks, and how to manage them?',
    meta: {
      title:
        'The Great Wealth Transfer: Risks, Challenges & Opportunities for Asset Preservation',
      desription:
        'Discover key risks, challenges, and strategies for preserving assets during the largest intergenerational wealth transfer. Learn how to protect family wealth and manage capital with insights from the Owner.One Penguin Analytics Report.',
      keywords:
        'Great wealth transfer, intergenerational wealth transfer, asset preservation strategies, risks of wealth transfer, Owner.One research report, Penguin Analytics, capital transfer challenges, family wealth management, digital solutions for asset transfer',
      og: {
        title:
          'The Great Wealth Transfer: Risks, Challenges & Opportunities for Asset Preservation',
        desription:
          'Discover key insights into the largest intergenerational wealth transfer in history, projected to exceed $72.6 trillion by 2045. Learn about the risks, challenges, and strategies for effective asset preservation based on the Owner.One Penguin Analytics Report.',
        image: '/images/blog/great_wealth_preview.png',
      },
    },
    slug: 'the-great-wealth-transfer-risks-challenges-and-opportunities-for-asset-preservation',
    content: [
      block([
        text(
          'The great intergenerational wealth transfer has already begun. It will be the largest in history. Experts '
        ),
        link(
          'expect',
          'https://finance.yahoo.com/news/billionaire-ray-dalio-says-73-180810802.html'
        ),
        text(
          ' families and others to receive more than $72.6 trillion from capital founders by 2045. What are the key risks, and how can they be managed?'
        ),
      ]),
      block([
        bold('Families with a net worth of $3M–$99M'),
        text(
          ' are in the most vulnerable position. When it comes to the transfer of assets and capital, they account for up to 75% of all losses. '
        ),
      ]),
      block([
        link('Owner.One', 'http://owner.one/'),
        text(
          '’s research, referred to as Penguin Analytics, details the reasons behind this. A total of '
        ),
        bold('13 500 capital founders from 18 countries took part in it.'),
      ]),
      //
      h2('Drawing from the report, the article offers key insights into:'),
      list([
        text('Asset and capital transfer'),
        text('Ownership continuity'),
        block([bold('Know Your Customer (KYC)'), text(' procedures')]),
        text('The use of digital technology to address these challenges.'),
      ]),
      //
      h2('Wealth owners: ready to lose up to 50% of their fortune'),
      text(
        "Penguin Analytics shows that 23.7% of capital founders are prepared to give up to 25% of their wealth. They are ready to accept the loss in exchange for a guarantee that the rest will remain within their family's control. Additionally, 11.8% are willing to lose up to 50% for the same guarantee."
      ),
      text(
        'Only 11.9% of them recognize that, in the future, their children will need to complete KYC procedures for both themselves and their parents. This requirement is crucial because it ensures family assets and capital are documented from their origin, starting with the founding member.'
      ),
      block([
        text(
          'Less than 5% of the founders know the consequences of their inaction. It shifts the wealth transfer '
        ),
        bold('problem'),
        text(
          ' to their family and children. They will not have the necessary tools to tackle the related challenges and obstacles associated with the task.'
        ),
      ]),

      //
      h2('Asset information storage: insecure and insufficient'),
      text(
        'In 81% of cases, capital owners personally handle the record-keeping and storage of asset information. They allocate their leftover time to this task.'
      ),
      text(
        'However, the overwhelming majority opt for insecure methods to store and update their asset data. According to  Penguin Analytics, 97.3% of them make these unreliable choices.'
      ),
      text(
        'Only 17.5% of respondents know that capital founders lose up to one-sixth of their assets data each year. This data loss is often difficult or impossible to recover. The chances for family members to retrieve these records are even slimmer.'
      ),
      text(
        "Just 7% of capital founders and 7.8% of their heirs get that, in a force majeure event, they usually have a 3-6 month window to claim assets in most countries. Without detailed asset data and attributes, family members can only access the most available assets–often called the 'low-hanging fruits'."
      ),
      //
      h2('Are Losses Inevitable?'),
      text(
        "79.4% of capital founders doubt their family members' ability to understand information about assets and capital."
      ),
      text(
        "Furthermore, 48% of wealth owners think that their family won't be able to take possession of capital and assets, while 23.8% concede that family members might only partially succeed."
      ),
      text(
        "Only 5% of them grasp this: Experts view a net worth of $3M to $99M as wealth that will not last beyond one generation. This perspective comes from observing that in 69% of cases, a family's lifestyle declines after the wealth transfer."
      ),
      //
      h2('Unforeseen Risks of Crypto Assets'),
      text(
        'Fully 87% of respondents are unaware that recovering crypto assets becomes impossible if the basic data related to the asset gets lost.'
      ),
      text(
        'As a result, 23.7% of all crypto assets on the market lack identified owners. Merely 9.3% of survey participants know about this issue.'
      ),
      text(
        'Crypto payment services report that only 7% of clients express concern about the risk of disrupted ownership continuity before finalizing a transaction.'
      ),
      //
      h2('Low Trust Level for Third Parties'),
      text(
        'More than 89% of wealth owners have doubts about trusted individuals and professionals. They question whether these individuals will handle tasks well when the time comes or if they will act in the family’s best interest. In fact, 63.3% are entirely uncertain about this.'
      ),
      text(
        'In the surveyed target group, family trusts have a market penetration of 0.4%, while family offices reach 0.7%.'
      ),
      text(
        'Only 7.8% of family members are aware that attorneys are 77.6% more likely to exhibit unscrupulous behavior towards successors than towards capital founders.'
      ),
      //
      h2('Data Gaps Lead to Major Losses'),
      text(
        'Barely 2% of capital founders know that, on average, up to 31% of family wealth is lost during the transfer. The primary reason for this is the lack of comprehensive asset data.'
      ),
      text(
        'The most eagerly anticipated solution to wealth transfer challenges involves developing digital solutions. These aim to cut intermediaries and human-related risks by leveraging sophisticated algorithms.'
      ),
      text(
        'Currently, 71.4% of wealth owners are open to relying on a third party, provided it operates without human involvement. However, no products or services at the moment offer this capability.'
      ),
      //
      h2('Download Penguin Analytics'),
      text(
        'Dive into the comprehensive findings of the Owner.One’s research. It offers deeper insights and conclusions.'
      ),
      text(
        'Penguin Analytics is a chance to reflect. You can compare yourself against individuals from similar social circles. Afterward, you’ll be equipped to make well-informed decisions to protect both your assets and your family.'
      ),
      block([
        text('You can download Penguin Analytics via the '),
        link('link.', 'https://owner.one/analytics/'),
      ]),
    ],
  },
  {
    id: 2,
    title: 'Why switch to blockchain to store your assets and capital data?',
    description:
      'Until now, asset and capital data management has relied on decades-old technology. This has led to high costs, data breaches, and misconduct by third-parties. The introduction of blockchain technology to wealth management addresses these issues head-on. Explore how it protects your assets and capital information cost-effectively and securely.',
    contentImage: '/images/blog/blockchain.png',
    image: '/images/blog/blockchain.png',
    imageAlt:
      'A visual representation of traditional tech versus blockchain technology in asset management.',
    slug: 'why-switching-to-blockchain-now-is-the-best-option-for-your-assets-and-capital-data',
    meta: {
      title: 'Why switch to blockchain to store your assets and capital data?',
      desription:
        'Discover how blockchain technology can transform the management and transfer of your assets and capital data. Learn about the benefits of decentralization, security, and efficiency in wealth management.',
      keywords:
        'Wealth Transfer Solutions, Family Capital Management, Family Financial Security, Digital Asset Repository, Wealth Transfer Automation',
      og: {
        title:
          'Why Switching to Blockchain Now Is the Best Option for Your Assets and Capital Data',
        desription:
          'Discover how algorithmic hands-free asset transfer solutions empower high-net-worth individuals to manage their wealth seamlessly. Explore the benefits of a client-owned digital repository for secure and timely wealth transfer to family members, ensuring financial security and legacy planning.',
        image: '/images/blog/blockchain_preview.png',
      },
    },
    content: [
      image(
        '/images/blog/blockchain_graph.png',
        'Overview of Wealth Information Storage Methods'
      ),
      text(
        'Until now, asset and capital data storage has relied on decades-old technology. This has led to high costs, data breaches, and misconduct by third parties. The introduction of blockchain technology to the wealth industry addresses these issues head-on. Discover how it safeguards your assets and capital information cost-effectively and securely.'
      ),
      h2('Adoption on the rise'),
      text(
        'Blockchain is one step away from full-fledged adoption by traditional finance (TradFi) institutions. Crypto is its first real-world use case. By 2025, the overall crypto market cap will reach $7.5 trillion. The recent SEC’s approval of the spot bitcoin ETFs for BTC and ETH signals that the adoption wave is nearing.'
      ),
      text(
        'The wealth industry is typically considered a conservative part of TradFi. Private services and family offices are cornerstones of the industry. The majority of the market players are still running on old business models and tech setups. More importantly, they are rarely able to properly address issues of information asymmetry within wealthy families. The latter is one of the prime reasons for failures in multigenerational wealth transfer, accounting for 31% of total family wealth losses in the process.'
      ),
      text(
        'But what is the information asymmetry? In short, capital owners avoid disclosing all information about their capital and assets to their families, not wanting to do it too early. However, doing it too late will be impossible. The methods commonly used by most people neither resolve the issue nor allow them to act at the right moment'
      ),
      text(
        'However, high-income families seek more secure solutions to address the issue. This need is propelled by the following discontents'
      ),
      list([
        text(
          'Outdated tech setups result in costly data storage, limit direct access to the information, and create room for misconduct by third parties.'
        ),
        text('A labor-intensive process with low levels of automation.'),
        text(
          'The costs of wealth information storage reach up to $180,000 per year.'
        ),
        text(
          'Centralized data registries experienced a number of infamous breaches and leaks. It led to private information being unauthorizedly disclosed. '
        ),
      ]),
      h3Light('Capital founders understand everything but do nothing'),
      list([
        text(
          'On average, up to $310,000 per $1M of family fortune is lost during wealth transfer due to gaps in asset information.'
        ),
        text(
          'Families with $3M–$99M in assets face the highest risk in intergenerational handovers, accounting for 75% of total losses.'
        ),
      ]),
      block([
        text('These conclusions derive from '),
        link('Owner.One’s', 'https://owner.one/analytics/'),
        text(
          ' Penguin Analytics Report, a comprehensive study surveying 13 500 capital founders across 18 countries.'
        ),
      ]),
      text(
        'To date, it is one of the largest global studies on how the rich transfer assets within their families. It evaluates common strategies and risks originating from both planned and force majeure handovers from founders to family members.'
      ),
      text(
        "Fully 36% of wealth owners are ready to accept a 10-50% loss for a guarantee that the rest will go to the family. Moreover, 86% think their family can't understand data on family assets and capital."
      ),
      text(
        'The paradox is that only 4.5% realize their inaction in preventing the information asymmetry shifts the burden of its consequences to their families. The unresolved issue leaves them without the means to tackle the complexities related to intergenerational handovers.'
      ),
      text(
        "Penguin analytics revealed a lot of immaturity in addressing family wealth transfer challenges. It's a common attitude among high-income families and individuals. However, once they assess the underlying issue behind information asymmetry, they sharply recognize its importance in connection to their family dynamics."
      ),
      text(
        'Despite that, no action is being taken afterward. Merely 6% of capital owners have established or are in the process of drafting an assets and capital transfer plan.'
      ),
      text(
        'The bottom line is that the problem is clear for all the parties involved in wealth transfer. Founders assess risks from gaps in asset data. They are ready to pay to cover them, but not willing to solve them in advance.'
      ),
      text('This raises an important question: Why is this the case?'),
      h3Italic('Founders’ stress is not remedied'),
      list([
        text(
          '68% find it extremely stressful even to consider how to store and reliably transfer information about their assets and capital to family members.'
        ),
        text(
          '97.3% opt for insecure methods to store and update their assets and capital data.'
        ),
      ]),
      text(
        'Annually every capital founder loses up to 1/6 of all their assets’ history of origin data. That information is difficult or impossible to restore. '
      ),
      bold(
        'In 81% of cases, owners handle record-keeping and storage themselves. These are their choices for the task:'
      ),
      list([
        block([text('Box of documents in the cabinet - '), bold('24.8%')]),
        block([text('Spreadsheet or list on paper - '), bold('24.3%')]),
        block([text('Cloud storage - '), bold('19.8%')]),
        block([text('Haphazardly (randomly, ad hoc'), bold(') - 18%')]),
        block([
          text('Partly at a hired professionals’ partly at home - '),
          bold('5.3%'),
        ]),
        block([
          text("My spouse files it. I don't really know how, but I will ask -"),
          bold('4.9%'),
        ]),
        block([text('Other - '), bold('2.6%')]),
      ]),
      text(
        "The breakdown clearly indicates the bedrock of the problem. Illogically, it’s the wealth owners' approach to the family’s money and estate that leaves their loved ones in the most vulnerable position during the wealth transfer."
      ),
      h3Italic('Is there a better option?'),
      text(
        'Today, decentralized technology supports applications in many industries. Its expansion is reshaping traditional business processes and models. In finance, it enables quicker and more secure transactions, providing a desirable level of confidentiality.'
      ),
      text('But how can it address wealth transfer challenges:'),
      list([
        text('How can it ensure 100% safety of assets and capital data?'),
        text(
          'What guarantees are there for direct access to data when the time comes?'
        ),
        text(
          'Can eliminating intermediaries also remove the associated extra costs?'
        ),
      ]),
      text(
        'We’re inviting wealth owners and their families to take a step-by-step approach to explore all the ways to use decentralized and blockchain technologies for asset and capital data filing and storage.'
      ),
      h3Italic('What are the origins?'),
      text(
        'Blockchain was designed for Bitcoin users and early cryptocurrency adopters. However, its user base has since expanded to include businesses, governments, and individuals seeking new solutions to old problems in transactions, data storage, and digital trust.'
      ),
      text(
        'In Q1 2024, Bitcoin became the eighth most valuable asset globally by market cap. With a record high of more than $72,000, its market value rose to $1.42 trillion, surpassing silver.'
      ),
      text(
        'The success of the BTC ETFs launch accelerated the adoption of underlying technology. More and more banks and other financial institutions are moving to decentralized and blockchain-based solutions to guarantee the efficiency and confidentiality of their client operations. '
      ),
      text(
        'At its core, blockchain is a decentralized digital ledger that records transactions across a network of computers. It does so in a way that ensures security, transparency, and immutability. Its cornerstone is full data encryption.'
      ),
      text(
        'Traditional ledgers or databases are controlled by a single entity. Blockchain operates without a central authority. Paired with end-to-end data encryption, it makes access to sensitive client information resistant to the possibility of fraud or breaches—a phenomenon now tightly bound to the wealth information storage approaches of the past decades.'
      ),
      h3Italic('What is a smart contact?'),
      text(
        'Blockchain offers a new way to handle and secure asset information. For example, it enables the creation of smart contracts, which are self-executing with terms that cannot be amended.'
      ),
      text(
        'This technology by design eliminates the need for intermediaries. It cuts costs and avoids the complexity associated with similar services in TradFi.'
      ),
      text(
        'Moreover, it provides unparalleled transparency. Once a transaction is recorded, it cannot be changed or deleted. This provides a secure and accurate history of asset movements and ownership. '
      ),
      text(
        'The latter is highly valuable in the wealth industry. The crystal-clear level of transaction history is key in facilitating smooth intra-family handovers or preparing for Know Your Customer (KYC) procedures.'
      ),

      h3Italic('No room for middlemen?'),
      text(
        'For a long time, family offices and private service providers faced little competition. It led to high entry barriers and significant fees for their services.'
      ),
      text(
        'Blockchain cuts costs by reducing reliance on intermediaries. This change is crucial for the new wave of wealthy individuals whose demands are not currently being met. '
      ),
      text(
        'Penguin Analytics indicates that these groups have low trust in the existing TradFi offerings. They look for cutting-edge technology to obtain full ownership of their assets and capital data, and its future. '
      ),
      text(
        'According to Penguin Analytics, the current level of market penetration for family trusts is 0.4% in the HNWI segment. For family offices, the rate is 0.7%.'
      ),
      h3Italic('How secure is it?'),
      text(
        'Blockchain does not offer absolute security, but it offers more security than any other existing technology in the industry. As of now, no known cases of blockchain breaches are reported.'
      ),
      italic('In a blockchain, encryption is a cornerstone:'),
      list([
        text(
          'All transactions are encrypted. This makes the data unreadable to unauthorized users. Only those with the right keys can decrypt the transaction and view the information.'
        ),
        text(
          "Participants sign transactions using their private keys, creating digital signatures. These signatures verify the transaction's origin. They ensure that the data was not tampered with and confirm the sender's identity."
        ),
      ]),
      h3Italic('What is the use case for family capital?'),
      text(
        'Decentralized technology may improve family capital operations in two main ways. First, it can digitally encapsulate any asset. Second, it enables data exchange in a decentralized and encrypted manner.'
      ),
      text(
        'This has opened up new paradigms of asset data storage and transfer. Founders and their family members can exchange data outside conventional TradFi-related structures, avoiding the associated risks. As highlighted in Penguin Analytics, 77.6% more often lawyers act unscrupulously toward successors, than toward capital founders.'
      ),
      text(
        'The new technology allows founders to store a master copy of their assets and capital data in a decentralized way. What are the benefits? Even if an attacker compromises one server, the information stays fragmented and encrypted, ensuring that unauthorized people will not access it. '
      ),
      h3Italic('Is it regulated'),
      text(
        'The global regulatory landscape continues to evolve across jurisdictions. In the U.S., it is unclear if decentralized ledgers can be the main source for ownership records.'
      ),
      text(
        'In contrast, some countries have passed such laws. These countries include Germany, Luxembourg, and Singapore. They officially enable blockchain record-keeping.'
      ),
      text(
        'Smart contracts may also serve as legal proof of the capital founder’s will if needed. Since 2018, some US states and EU countries, Switzerland, Singapore, and the UAE have started developing case law. These laws treat digital solutions (like smart contracts) as complete alternatives to wills.'
      ),
      h2('The industry outlook'),
      text(
        'Banks and financial firms are increasingly focusing on DeFi. Several prominent traditional finance firms, including BlackRock, HSBC, BNP Paribas Securities, and BNY Mellon, have recently invested in a range of DeFi projects, each targeting different areas within the digital assets space, including the use of blockchain to tokenize real-world assets.'
      ),
      text(
        'However, at the moment, there is limited utilization of DeFi and blockchain capabilities for multigenerational wealth transfer issues.'
      ),
      h2('Anticipated, yet non-existing solution'),
      list([
        text(
          '71.4% of capital founders are willing to rely on a third party for the transfer if it can be done without human involvement. Currently, there are no products or services that offer such capabilities.'
        ),
      ]),
      text(
        'Wealthy individuals are eager for a solution to the challenges posed by wealth transfers. Penguin Analytics indicates that they opt for a single, digital infrastructure that can cut out intermediaries and human-related risks in intra-families handovers. '
      ),
      text(
        "This vision agrees with the findings in Capgemini's 2023 report. The report envisions a future dominated by digital services under a Wealth-as-a-Service model. Presently, no such products are available on the market."
      ),
      text(
        'Owner.One’s research team gathered insights on the desirable features of wealth transfer assistance service from the target audience–capital owners with a net worth ranging from $3m to $99m. Interviewees outlined the objectives as follows:'
      ),
      listNumeric([
        text(
          'Storing extensive data and attributes on assets. Storing document copies was not an A-level priority.'
        ),
        text(
          'Ensuring secure transmission of asset information to family members or authorized individuals.'
        ),
        text(
          'The capability to pre-set the precise timing or conditions under which the wealth data transfer will be initiated.'
        ),
      ]),
      text(
        'Client apps from brokers, insurers, banks, and payment firms fall short of meeting these needs through a single and holistic digital infrastructure. They serve as mere communication channels between customers and institutions. Their main aim is to simplify client operations and reduce corporate expenses.'
      ),
      text(
        'In Penguin Analytics, we sampled about 240 software programs used by family offices, lawyers, advisors, and other industry players.'
      ),
      text(
        'Some of them (30%) mimic online products but they are really just marketing products. Businesses utilize them to find clients and direct them into offline interactions.'
      ),
      text(
        'About 50% are similar to banking apps. They enhance current client communications.'
      ),
      text(
        'The rest (20%) are marketing products. They promote online investment-focused products and services under the guise of handling family assets and capital.'
      ),
      text(
        'Notably, we identified no scam apps in a sampled range. The lack of fraud in this area is, of course, encouraging. However, it also suggests that the industry does not attract leading actors, as scammers tend to follow them.'
      ),
      bold(
        'So, it is reasonable to state that the market does not offer any services that:'
      ),
      list([
        text('Enable users to create a personal assets data repository.'),
        text(
          'Grant data ownership to the client rather than a financial institution.'
        ),
        text(
          'Replace intermediaries with algorithms in the family capital information transfer chain.'
        ),
        text('Provide secure and encrypted data storage.'),
        text(
          'Enable capital founders to share their assets and capital information with family members at the right moment.'
        ),
      ]),
      h2('What is Owner.One?'),
      block([
        link('Owner.One', 'http://owner.one/'),
        text(
          ' enables algorithmic hands-free transfer of assets data from a capital founder to family members.'
        ),
      ]),
      text(
        'Wealth transfer self-acting algorithms will work automatically, never too early, never too late, but just-in-time. It will be triggered by life events, outlined by the owner and will reach the addressee.  '
      ),
      text(
        'Wealth owners acquire a client-owned autonomous infrastructure for inventory, control, and transfer of capital and assets data. No third parties are needed. Owner.One is designed for individuals with assets worth $3M to $99M.'
      ),
      h2('What are Owner.One’s key features?'),
      text(
        'Our service helps capital founders. They can easily prepare data on assets and capital for transfer. They can transfer it to family or other chosen people. '
      ),
      text('Here are the top five features, available only via Owner.One'),
      list([
        block([
          bold('My Hub.'),
          text(
            ' The single and secure server to store all information about your family’s wealth. Owner.One acts only as a browser for uploads and edits, while MyHub functions independently. No third parties, including Owner.One, will have access to your data.'
          ),
        ]),
        block([
          bold('Assets attributes.'),
          text(
            ' Choose from 40+ asset classes. Each comes with a dedicated template. Utilize templates to upload information on your assets and capital. Use the quick start feature to speed up data entry. Once filled in, wealth information is transfer-ready. Delegate data entry to assistants or trusted professionals. They will perform the task in a secure and restricted environment, with no ability to view any sensitive information.'
          ),
        ]),
        block([
          bold('Personal dashboards.'),
          text(
            " Track your portfolio's performance across different asset types, jurisdictions, and generations. Filter data by currency, value, liquidity, and other parameters to gain a 360-degree view of your wealth."
          ),
        ]),
        block([
          bold('Transfer Algorithms.'),
          text(
            ' Wealth transfer self-acting algorithms will work automatically, never too early, never too late, but just-in-time. It will be triggered by life events, outlined by the owner and will reach the addressee.  A family member will receive all required information to claim ownership. No extra approval or permission is needed for them to access the data. It will be delivered by self-acting algorithms and made available to them through the Owner.One app.'
          ),
        ]),
      ]),
      h2('Is Owner.One encrypted?'),
      text(
        'Owner.One is blockchain-based. It ensures no third party can access customer data, not even Owner.One.'
      ),
      list([
        text(
          "Within Owner.One, all data is encrypted with a user's unique key. The user keeps this key. It ensures their sole right to decrypt. This ensures that no one, not even Owner.One itself, can access this information."
        ),
        text(
          "Multiple independent servers distribute and store data in pieces. The assembly of information happens on the client's device. This adds an extra layer of protection. Even if someone breaches a server, the information remains fragmented and encrypted. Backup copies are also encrypted. They are also stored in pieces on many servers. This offers more protection."
        ),
      ]),
      text(
        'You can learn more on our product page. It details how Owner.One helps you keep your assets and capital data transfer-ready.'
      ),
      h2('ARTICLE SUMMARY'),
      listNumeric([
        text(
          'The move from a traditional to a decentralized setup cuts costs. It also boosts security. '
        ),
        text(
          'A survey of 13 500 capital founders across 18 countries revealed high risks of data gaps for family wealth transfer. High-net-worth families lose more than others.'
        ),
        text(
          'Decentralized solutions remove middleman dependency in intra-family handovers.'
        ),
        text(
          'Owner.One ensures users own their assets and capital data. It enables secure assets and capital data transfer from a capital founder to family members. No third parties are needed.'
        ),
      ]),
    ],
  },
  {
    id: 3,
    title:
      '$340 000 is Lost for Every $1 000 000 in Family Wealth Transfers. Why?',
    description:
      'Most wealthy families lose 34% of their fortune in family wealth transfers - for no good reason. Are you and your family following the same path as others, or not? Find out now.',
    contentImage: '/images/blog/transfer.png',
    image: '/images/blog/transfer.png',
    imageAlt: 'Family wealth iceberg illustrating visible and hidden assets',
    slug: '340000-is-lost-for-every-1000000-in-family-wealth-transfers-why',
    meta: {
      title:
        'Why Wealth Transfers Lose $340,000 Per $1 Million: Owner.One Insights',
      desription:
        'Discover why up to 34% of family wealth is lost in transfers. Learn how Owner.One’s Assets Data Repository and Wealth Transfer service safeguards your assets through data integrity, knowledge symmetry, and perfect timing',
      keywords:
        'wealth transfer, family wealth management, data integrity, information asymmetry, Owner.One, asset transfer, wealth preservation, inheritance planning, luxury asset management',
      og: {
        title: 'Why 34% of Family Wealth is Lost in Transfers ',
        desription:
          'Most wealthy families lose $340 000 per $1 million during asset transfers. Discover how Owner.One prevents losses with secure, algorithmic wealth data management.',
        image: '/images/blog/transfer.png',
      },
    },
    content: [
      text(
        'Most wealthy families lose 34% of their fortune in family wealth transfers - for no good reason. Are you and your family following the same path as others, or not? Find out now.'
      ),
      h2('Why Do Families Like Yours Lose Up to 34%?'),
      block([
        text(
          'Owner.One, an Assets Data Repository and Wealth Transfer service for families worth $3M-$99M in assets, surveyed 13 500 capital founders across 18 countries. '
        ),
        link(
          'According to the research,',
          'https://owner.one/analytics/?utm_source=blog&utm_medium=article&utm_campaign=340000-is-lost-for-every-1000000-in-family-wealth-transfers-why&utm_content=link-analytics'
        ),
        text(
          ' referred to as Penguin Analytics, this category of wealth owners accounts for up to 75% of all losses that occur during the transfer of assets from capital founder to the family. On average, $340 000 per $1 000 000 is lost in the process.'
        ),
      ]),
      text(
        'A net worth of $3M to $99M often lasts just one generation. In 69% of cases, families experience a lifestyle decline after the transfer.'
      ),
      h2('Three ways to lose 34% of your assets'),
      text(
        'There are three main reasons behind these potential losses. These are:'
      ),
      list([
        text('data gaps'),
        text('information asymmetry'),
        text('wrong timing '),
      ]),
      text(
        'This article will analyze this trio of possible pitfalls. We will show you how to ring-fence your hard-fought family wealth. '
      ),
      h2('What are gaps in wealth data?'),
      text(
        "Only the capital founders know the in-depth details of a family's wealth. This will include asset classes, jurisdictions, agreements, related business contacts, and other vital attributes. "
      ),
      text(
        'A gap in wealth data arises when relevant parties are unaware of information related to the assets. This can stall, or completely halt, the transfer process. Typically, each asset requires up to 22 attributes to  complete the task seamlessly.'
      ),
      text(
        'For example, brokers can legally refuse to respond to a family’s inquiries if critical details, such as account and contract numbers, or the specifics of the signing entity are missing. This becomes challenging as some brokers manage between 30 to 250 licensed and sub-licensed entities.'
      ),
      h2('What is information asymmetry?'),
      text(
        'Imagine your family wealth as an iceberg. Family members see only what’s above the surface - 90% of its true structure is hidden. '
      ),
      text(
        "This mirrors the issue of information asymmetry when family members don’t know the real structure of a family’s wealth. Typically, they will have a 3-6 month window to claim assets in most countries. Without full attributes, the family could claim only the 'low-hanging fruit', if anything at all. "
      ),
      h2('What is wrong timing in wealth data transfer?'),
      text(
        'Timing in wealth data transfer answers the question: Who needs to know what and when do they need to know it?'
      ),
      text(
        'If a family receives it too early, they might be unprepared to handle it responsibly. On the other hand, delays lead to assets becoming inaccessible or lost. For instance, 22.7% of bank deposit box rentals, worldwide, are overdue. This means that access to them is restricted. Furthermore, 16% of luxury homes have unidentified owners.'
      ),
      h2('How to fix your wealth data with Owner.One'),
      text(
        'Owner.One is tailored exclusively for wealth owners with net worth between $3 million and $99 million. Our service equips them to make wealth information transfer-ready. It enables algorithmic hands-free transfer of assets data from a capital founder to family members. '
      ),
      text(
        'Wealth transfer self-acting algorithms will work automatically, never too early, never too late, but just-in-time. It will be triggered by life events, outlined by the owner and will reach the addressee.  '
      ),
      text(
        'Wealth owners receive a client-owned autonomous infrastructure for inventory, control and transfer of capital and assets data. No third parties are needed.  '
      ),
      text(
        'Here is how Owner.One can remedy data gaps, information asymmetry, and inopportune timing.'
      ),
      list([
        block([
          bold('Data integrity.'),
          text(
            "Store, manage, and transfer information on all your assets in one place with MyHub. It’s your client-owned vault for wealth data. MyHub ensures confidentiality and security. Even if you stop being an Owner.One client, you'll still retain access to MyHub."
          ),
        ]),
        block([
          bold('Symmetry in family knowledge.'),
          text(
            'Owner.One covers over 40 types of assets. It includes bank and brokerage accounts, real and commercial estate, legal entities, stocks, jewelry, and more. Each asset type comes with a specially designed template. Once filled out, these templates ensure that all necessary information is ready to pass on to family members.'
          ),
        ]),
        block([
          bold('Perfect timing.'),
          text(
            'You can specify what information each person receives. Then you can set up automatic data transfer processes to convey wealth data. Designated family members will receive the information automatically - not too early, not too late, but just-in-time. No intermediaries will be involved in the process.'
          ),
        ]),
      ]),
      h2('Final Conclusions'),
      text(
        'We analyzed 240 apps and programs used by family offices, lawyers, and consultants within the wealth industry.'
      ),
      text(
        "Half of them are marketing apps that are aimed at directing customers to offline upsells. The remaining are software applications that support family offices' client operations."
      ),
      text(
        'However, wealth owners do seek technology-driven setups for their assets and money. Fully 71% are open to using third-party digital services that operate autonomously without human intervention. This capability is as yet not offered by institutions marketing themselves as family wealth solution providers.'
      ),
      text(
        'Owner.One covers that need. Consult our product and pricing pages to learn more.'
      ),
    ],
  },
  {
    id: 4,
    title: 'Where Do High-Nets Keep Their Money?',
    description:
      'HNWIs and UHNWIs know how to build wealth, but where do they store it? Our study shows that 75% concentrate their assets in the riskiest categories for family wealth transfer. What are these assets, and what are the associated risks?',
    contentImage: '/images/blog/highnets.png',
    image: '/images/blog/highnets.png',
    imageAlt:
      'depicting common asset allocations for high-net-worth individuals, including cash, real estate, and stocks',
    slug: 'where-do-wealthy-people-keep-their-money',
    meta: {
      title:
        'Where Do High-Nets Keep Their Money? Insights on Wealthy Portfolio Structures',
      desription:
        'Discover how HNWIs and UHNWIs manage their wealth, from conservative investments to cash and real estate. Learn about asset risks and misconceptions in family wealth transfer based on Penguin Analytics’ research.',
      keywords:
        'high-net-worth individuals, UHNWIs, asset liquidity, family wealth transfer, conservative portfolios, Penguin Analytics, cash equivalents, real estate investments',
      og: {
        title: 'Where Do High-Nets Keep Their Money?',
        desription:
          'Explore where HNWIs and UHNWIs store their wealth. From cash to real estate, see the top and rarest assets, risks involved, and what Penguin Analytics reveals about family wealth transfer.',
        image: '/images/blog/highnets.png',
      },
    },
    content: [
      text(
        'As the first generation of wealth creators, HNWIs and UHNWIs know how to build fortunes and identify money-making opportunities. But where do the rich store their wealth? Our study shows that 75% of them concentrate their assets in categories that are riskiest in the context of family wealth transfer. What are these assets, and what are the associated risks?'
      ),
      h2('KEY POINTS'),
      list([
        text(
          'High-net-worth individuals often follow common misconceptions in assessing asset liquidity in relation to family wealth transfer. In reality, it’s not the same as the ease of transferring assets to the next generation.'
        ),
        text(
          '31% keep their money in deposit boxes, banks, or brokerage accounts.'
        ),
        text(
          'Only 3.29% have investments in alternative assets. These include art, collectibles, wines, digital assets, rare cars, and others. '
        ),
      ]),
      h2('Wealthy families: portfolio structures '),
      text(
        '$340,000 per $1,000,000 is an average loss during the family wealth transfer for HNWI and UHNWI families. It can reach 72%-91% for certain asset classes. Overall, these families bear three-quarters of all total losses that occur during the transfer. '
      ),
      text(
        'To uncover the reasons behind this, we surveyed 13,500 capital founders from 18 countries. Their net worths range from $3 million to $99 million. Penguin Analytics, our report, also analyzes the portfolio structures of wealthy families.'
      ),
      h2('TOP 5 MOST POPULAR ASSETS:'),
      listNumeric([
        text('Residential real estate - 12,5%'),
        text('Bank accounts - 12,4%'),
        text('Brokerage accounts - 10,8%'),
        text('Stock and shares in private companies - 9,1%'),
        text('Stock in public companies - 8,1%'),
      ]),
      h2('TOP 5 RAREST ASSETS:'),
      listNumeric([
        text('Family (private) funds, trusts - 0,45%'),
        text('Tokens secured by physical assets - 0,62%'),
        text('Rights to intangible assets - 0,65%'),
        text('Planes, helicopters, yachts, boats - 0,75%'),
        text('Venture fund investments - 0,83%'),
      ]),
      text(
        'High-nets tend to favor conservative or ultra-conservative portfolio structures. The breakdown shows that respondents hold 53.4% in non-financial assets and 46.6% in financial assets. '
      ),
      h2('Cash and cash equivalents'),
      text(
        'Market reports identify that rich people allocate 25% to 34% of their assets to cash–more than any other asset class. '
      ),
      text(
        'In emergencies, access to cash offers the agility for quick decisions. This is why the wealthy often keep much of their wealth in cash and cash equivalents.'
      ),
      text(
        "Yet, with prevailing inflation rates, the focus on cash diminishes. Inflation erodes money's purchasing power. "
      ),
      block([
        bold('Fact:'),
        text(
          ' 4.7% of capital owners know that 22.7% of bank deposit boxes are overdue at any time. Access to them is restricted. Also, 14.2% of deposit boxes worldwide do not have current identified owners.'
        ),
      ]),
      block([
        bold('Risk:'),
        text(
          ' 2.6% know that in some countries if a deposit box is not renewed on time, the bank will open it with the police present. To retrieve the money and valuables, you must explain the origin of the funds. '
        ),
      ]),
      h2('Real estate'),
      text(
        'Currently, high-nets hold about 12% to 15% of their wealth in real estate.'
      ),
      text(
        'Often, they first invest in their primary residence. Afterwards, they buy more properties. They typically rent these out. Later, some may add commercial properties to their portfolio. These include office buildings, hotels, warehouses, and more.'
      ),
      text(
        'Real estate might not offer quick liquidity. Many people view it as a profitable long-term investment. It also provides reliable passive income for millionaires.'
      ),
      text(
        'Owning real estate allows for tax-free growth in net worth. Property values are only taxed when you sell.'
      ),
      block([
        bold('Fact:'),
        text(
          ' About 16% of prestigious homes worldwide are classified as “owner not identified”.'
        ),
      ]),
      h3('Bonds'),
      text(
        'Wealthy individuals put 12%-15% of their wealth into bonds. The pro is adding a stable asset to the family portfolio, which generates a steady income stream.'
      ),
      text(
        'Bonds are considered a safer choice than stocks. The downside is lower returns. '
      ),
      text(
        "On the other hand, it's an asset tailored for wealth storage. Some bonds are optimized for taxes. Others help to hedge portfolios against inflation."
      ),
      block([
        bold('Risk:'),
        text(
          ' Active bond management might be required from wealth owners due to inflation or political instability.'
        ),
      ]),
      h2('Private equity and hedge Funds'),
      h3('Dividend stocks'),
      text(
        'Penguin Analytics indicates that UHNWI and HNWI families dedicate 17.2% of their assets to stocks, with which they target two main goals.'
      ),
      text(
        'First, they aim to expand the share in their portfolio that generates passive income. Dividend-paying stocks complement rental income from real estate.'
      ),
      text(
        'Second, high-nets focus on diversifying not just their portfolios but also their time. Consequently, they’re on a mission to save room for new experiences rather than dedicating all of their time to managing investments on a daily basis. '
      ),
      text(
        'Dividend stocks check all the boxes. Additionally, they offer some tax advantages as well. A long-term investment in stocks also allows their net worth to grow tax-free through capital gains, as profits in appreciating stock prices are only taxed when sold.'
      ),
      h3('Mutual funds and ETFs'),
      text(
        'A recent study identifies that high-nets keep a growing portion of their assets in mutual funds and exchange-traded funds (ETFs). Millionaires are attracted to them as a fast-track option to diversify their portfolios and adjust investment strategies to market shifts.'
      ),
      text(
        'In essence, ETFs serve as an investment vehicle to pick out new assets without the need to directly acquire them. For example, at the beginning of 2024, the first bitcoin ETFs received a green light from the U.S. Securities and Exchange Commission (SEC). BTC ETFs allow investors to gain exposure to the price movements of BTC, but without actually holding the bitcoin itself.'
      ),
      text(
        'In the end, mutual funds and ETFs contribute to building an extra security layer in portfolios to mitigate risks. '
      ),
      block([
        bold('Risk:'),
        text(
          " Brokers have legal grounds not to answer the family's requests. They can do so if details are missing. These include account and contract numbers, and specifics of the signing entity. The latter can be challenging. Some brokers manage between 30 to 250 licensed and sublicensed entities. This is especially true when the contract is signed online."
        ),
      ]),
      h2('Alternative investments'),
      text(
        'Passion assets, collectibles, crypto, art, fine wines, rare cars, and other alternative investment assets are not a priority for high-nets. According to Penguin Analytics, just 3.29% go for them to structure family wealth.'
      ),
      text(
        'The explanation might be the following. As mentioned above, UHNWIs and HNWIs prefer conservative or ultra-conservative portfolio structures. Most of them have no exposure to alternative investments. They may also feel insecure about locking their fortunes in asset classes where liquidity level might seem challenging to assess in the long term.'
      ),
      block([
        bold('Fact:'),
        text(
          ' Cryptocurrencies, like Bitcoin and Ethereum, are more volatile than tokens tied to physical assets, like platinum and oil. Meanwhile, they are more popular among our respondents. 4.32% invest in cryptocurrencies versus just 0.62% in asset-backed tokens.'
        ),
      ]),
      h2('4 Key Questions'),
      h3('What do HNWIs and UHNWIs get wrong in assessing asset liquidity?'),
      text(
        'People often prefer certain financial assets because they assume they are more liquid than others. High-net-worth individuals believe these assets are quicker to transfer to family members.'
      ),
      text(
        'In practice, the process is not as efficient as expected. Capital loss risks peak during both planned and unplanned transfers.'
      ),
      text(
        'Furthermore, the situation has recently become more complex. This is due to KYC requirements. They regulate matters of ownership continuity and sources of wealth origin. The rules get stricter when the founder transfers capital to family and successors.'
      ),
      h3(
        'What do millionaires do with their money? What are the most commonly included assets in family portfolios? '
      ),
      text(
        'Families mostly prefer cash and its equivalents. 31% keep their money in deposit boxes, banks, or brokerage accounts. Real estate makes up 12.5% of their portfolios. Next comes stocks in private companies at 9.1%, and stocks in public companies at 8.1%.'
      ),
      h3('What are the less common investment choices? '),
      text(
        'Lesser-chosen assets include private family funds and trusts at just 0.45%. Also, tokens backed by physical assets are at 0.62%, and rights to inventions at 0.65%. Planes, helicopters, yachts, and boats are at 0.75%, and venture fund investments at 0.83%.'
      ),
      h3(
        'Why is it important to keep up-to-date information on family wealth?'
      ),
      text('We named our report Penguin Analytics. Why?'),
      text(
        'Simply, penguins are remarkable creatures. They are family-oriented. They are highly adaptable to both heat and cold. Penguins swim underwater at speeds of up to 24 to 40 kilometers per hour. '
      ),
      text(
        'Yet when they step onshore, penguins become somewhat clumsy and ultra-slow, waddling at 3–6 km/h at most.'
      ),
      text(
        'The penguin metaphor reflects UHNWIs and HNWIs owners and the transfer issue. They’re highly skilled people at building the first generation of wealth. '
      ),
      text(
        'However, the task of passing it to the next generations within their family is a “penguin-on-shore” thing to them. The majority show lackluster results. Statistics are not on their side–7 out of 10 family wealth transfers fail.'
      ),
      text(
        'Penguin Analytics shows that families with a net worth from $3 million to $99 million are most at risk during the transfer. They account for 75% of total losses in this process.'
      ),
      text(
        'A study indicated that only 2% know that, on average, up to 31% of family wealth is lost during transfer due to gaps in data. '
      ),
      text(
        'A report outlines that to transfer assets effectively, you need to know up to 22 attributes for each asset. The founder and family often have a knowledge gap. This troubles families to find and claim assets.'
      ),
      h2('Owner.One for family assets and capital information'),
      text(
        'Owner.One focuses on algorithmic hands-free transfer of assets data from a capital founder to family members. '
      ),
      text(
        'Wealth transfer self-acting algorithms will work automatically, never too early, never too late, but just-in-time. It will be triggered by life events, outlined by the owner and will reach the addressee.  '
      ),
      text(
        'Wealth owners will get a client-owned autonomous infrastructure for inventory, control, and transfer of capital and assets data. No third parties are needed.  '
      ),
      text(
        'Customers can store, manage, and transfer information on banks and brokerage accounts, real and commercial estate, legal entities, stocks, jewelry, crypto, and commodity tokens. In total, over 40 assets are covered.'
      ),
      text(
        'For each, there is a specially designed template within the app. Once filled in, it ensures that all the information needed for a successful transfer or to meet KYC procedures is armed and ready.'
      ),
      h2('Conclusion'),
      text(
        'Penguin Analytics uncovered capital founders’ misconceptions about how they assess asset and capital liquidity and the reality of wealth transfer. '
      ),
      text(
        'For example, many stick to conservative portfolios with prevailing shares of cash and real estate. At the top of their minds, these assets are the first choice to store family wealth. '
      ),
      text(
        "On the contrary, the real environment of family wealth transfer is more than meets the eye. On a global scale, $260 billion of personal financial assets are in 'search' status. About $400-$420 billion in assets and property remain unclaimed. For digital assets, the estimate reaches 23.7%."
      ),
      text(
        'These numbers correlate with the overall level of loss that families experience when transferring wealth from one generation to the next. It totals 34%, but peaks at 91% for certain asset classes.'
      ),
      italic(
        'How do HNWIs and UHNWIs avoid the family wealth transfer trap? Consider these steps:'
      ),
      list([
        text(
          'Switch to modern tech setups to store wealth information. Unreliable approaches to storage cause annual losses of 1/6 of asset record history.'
        ),
        text(
          'Assess the liquidity of your portfolio for possible transfer. Identify gaps in data that block compliance with KYC requirements.'
        ),
        text(
          "Review your assets' vital attributes. Identify the required time periods to update them. On average, it takes a year to complete this task without automation tools."
        ),
      ]),
      h2('Disclaimer'),
      text(
        'The provided content is intended for informational purposes only and should not be construed as financial or investment advice. We advise all readers to conduct their own research before making any investment or financial decisions.'
      ),
      text(
        'While Owner.One endeavors to keep the information up-to-date and correct, we make no representations or warranties, express or implied, regarding the completeness, accuracy, reliability, suitability, or availability of the information for any purpose. Any reliance you place on such information is strictly at your own risk.'
      ),
    ],
  },
  {
    id: 5,
    title: 'Is your wealth transfer-ready?',
    description:
      'Wealth transfer from capital founders to their families will happen only once. There is no real-world way of testing this beforehand. This is possibly why 70% of families fail at it. How can you be confident that your family will not lose a third of their wealth because of bad preparation for wealth transfer?',
    contentImage: '/images/blog/transferReady.png',
    image: '/images/blog/transferReady.png',
    imageAlt:
      'depicting common asset allocations for high-net-worth individuals, including cash, real estate, and stocks',
    slug: 'is-your-wealth-transfer-ready',
    meta: {
      title:
        'Is Your Family Wealth Transfer-Ready? Avoid Losing Millions with Expert Insights',
      desription:
        'Discover how to safeguard your family’s wealth transfer. Our research reveals why 70% of families face major losses and how you can protect your legacy. Learn vital strategies for ensuring your wealth is well-prepared for future generations.',
      keywords:
        'wealth transfer, family wealth, asset management, high-net-worth, capital founders, financial planning, wealth loss, inheritance, generational wealth',
      og: {
        title: 'Is your wealth transfer-ready?',
        desription:
          '70% of families fail at wealth transfer, losing up to 34% of assets. Learn how to protect your legacy and prepare your family for a successful transfer with evidence-based insights.',
        image: '/images/blog/transferReady.png',
      },
    },
    content: [
      text(
        'Wealth transfer from capital founders to their families will happen only once. There is no real-world way of testing this beforehand. This is possibly why 70% of families fail at it. How can you be confident that your family will not lose a third of its wealth because of bad preparation for wealth transfer?'
      ),
      h2('Key takeaways'),
      listNumeric([
        text(
          'Wills are not shortcuts to success in family wealth transfers. In most cases, they are outdated, contested, and insufficient.'
        ),
        text(
          'Avoidance and sidelining are driving failures in equipping family members to act in the event of force majeure.'
        ),
        text(
          'Prioritize wealth information over static documents. The latter can be restored. Without elaborate information on assets, nothing can be done. '
        ),
      ]),
      h2('The Danger ahead'),
      text(
        'All families lose money in wealth transfers from one generation to the next. However, wealth owners, and their families, with assets ranging from $3 million to $99 million, face the highest failure rate. They account for 75% of total losses in both emergency and planned intergenerational transfers.'
      ),
      text(
        'What are the underlying reasons for this? Owner.One, a client-owned autonomous infrastructure for inventory, control, and transfer of capital and assets data for families who are worth $3M-$99M in assets, conducted the research to find evidence-based answers.'
      ),
      text(
        'In total, we surveyed 13,500 capital founders across 18 countries. According to the study, the average loss rate is 34%. It is estimated that this incurs losses ranging from $1.2 million to $34 million for these families.'
      ),
      block([
        bold('Fact: '),
        text(
          'Research conclusions are supported by data on assets with unidentified owners. For bank deposit boxes, the rate is 21%. For luxury homes, it is 16%, and for digital assets, it is 23%.'
        ),
      ]),
      h2('Acquiring, preserving, and transferring Wealth'),
      text(
        'It takes business acumen and entrepreneurial skills to build a fortune worth up to $99 million. As first-generation wealth creators, our respondents demonstrated outstanding abilities in both domains.'
      ),
      text(
        'However, their approach to managing family wealth lacks a comparable level of efficiency. Only 14% of them share pertinent information with their families. This is vital to facilitate the transfer. Conversely, 68% do not disclose any data at all. Moreover, in 98% of cases, wealth-related documents or wills are outdated or non-existent.'
      ),
      text(
        'If wealth owners applied similar principles to manage their business activities, they would probably run out of business quite quickly. Acquiring, preserving, and transferring wealth demands a different skill set.'
      ),
      text(
        'At this point, ultra and high-net-worth individuals (U/HNWI) stand on the opposite side of the fence, compared to ultra-rich families with a net worth starting from $100 million. The latter have already established family offices, trusts, or other legal structures to manage their assets.'
      ),
      text(
        'Our survey revealed that just 0.45% of U/HNWI families have trusts or family offices. In cases where they exist, these structures tend to manage only a portion of the wealth. On average, only 30% of their assets are managed by family offices, trusts, or funds.'
      ),
      text(
        'As a result, wealth owners with $3 million to $99 million and their families are chronically unprepared for the great wealth transfer. What are other factors contributing to this?'
      ),
      h2('Insecure data storage'),
      text(
        'Study findings suggest that, on average, up to 31% of family wealth is lost during transfer, due to a lack of comprehensive data on assets. What are the origins of these gaps?'
      ),
      text(
        'Fully 81% of capital founders prefer to maintain and manage records and store information, regarding family wealth, themselves. Unfortunately, in 97% of cases, they opt for unreliable methods to complete these tasks.'
      ),
      text(
        'It is indicative that most wealth owners utilize conservative, yet insecure methods. Almost half of this number (49%) simply put documents in a box, and use hand-written notes or spreadsheets.'
      ),
      text(
        'These practices result in approximately one-sixth of vital information being irretrievably lost annually. Without it, passing Know Your Customer (KYC) procedures or claiming assets becomes a near-impossible task for families.'
      ),
      h2('Information monopoly and asymmetry'),
      text(
        'In most cases, capital founders gravitate towards becoming the sole owners of family fortune-related information. They monopolize knowledge on asset classes, legal structures, sources of origin, relevant business contacts, instructions and limitations of power of attorney, etc. '
      ),
      text(
        'This also creates an information vacuum. Wealth founders are reluctant to share their knowledge in advance for two reasons. First, they fear that premature disclosure could trigger intra-family conflicts. Second, younger generations could lose their motivation for education and long-term career planning.'
      ),
      text(
        'At its core, wealth transfer touches on the most sensitive topics for every individual. Family relations and personal finances are not common topics for conversation, even with close friends or trusted professionals.'
      ),
      text(
        'For these reasons, family wealth transfer often becomes an elephant in the room. Understandably, 85% of wealth owners prefer not to disclose any information. Even when they do, the shared data often has no practical value in connection with the family wealth transfer. This could be because of a possible lack of completeness or currency.'
      ),
      h2('The Burden of avoidance'),
      text(
        'The common misconception is that transferring assets from one generation to the next will be straightforward. The process is perceived as obvious, with no conscious thought given to forward planning. '
      ),
      text(
        'According to the study, only 6% of wealth owners have drafted or are in the process of drafting a wealth transfer plan. In contrast, the next owner must know up to 20 details, and sometimes even more, about a single asset to be able to claim ownership. '
      ),
      text(
        'The evolving KYC requirements further complicate the matter. Regulations on capital origins and proofs of ownership continuity tighten when assets and capital are transferred from a wealth owner to successors.'
      ),
      text(
        'Unfortunately, only 4.5% of all respondents realize the consequences of their inaction. Avoidance of planning ahead shifts the burden onto family members and children. This significantly reduces their chances to successfully gain ownership.'
      ),
      text(
        'Eight out of ten capital founders do not take any action to resolve the information monopoly problem. This attitude largely contributes to the high family wealth transfer failure rate.'
      ),
      text(
        'As a result, 93% of respondents openly admitted that they had no understanding of how their family will act in the event of force majeure.  Can this be remedied?'
      ),
      image(
        '/images/blog/wealth_pie.png',
        'Cover image depicting documents and a family, symbolizing wealth and generational transfer'
      ),
      h2('Wealth transfer check-Up'),
      text(
        'Let us look at a step-by-step guide on how to avoid losing millions in the wealth transfer process.'
      ),
      h3('Step 1: Set a framework for the wealth transfer'),
      block([
        bold('Who are the next owners? '),
        text(
          'This is square one. Undoubtedly, family matters can be intricate. Before delving into a full-fledged wealth transfer strategy, take a step back and consider who are all the players in the process. Evaluate the eligibility of both immediate and extended family members. Then, assess how each stakeholder’s needs may influence decisions.'
        ),
      ]),
      block([
        bold('Which long-term obligations do you want to fulfill? '),
        text(
          'Some may require stable financial support for special care. For the younger generation, you may want to establish education opportunities. Life scenarios vary, but dedicating time to outline your vision and how you can support them or prevent actions you consider unacceptable is key.'
        ),
      ]),
      block([
        bold('What is your desirable endgame for the transfer? '),
        text(
          'Define the recipients, the assets, and the timing. Consider whether to transfer a particular asset entirely or to distribute it in shares among family members.'
        ),
      ]),
      block([
        bold('How do you address the information monopoly and asymmetry? '),
        text(
          'The best metaphor for family wealth is an iceberg. The largest part of it is always beneath the surface. Examine which vital information, about assets, is unknown to family members. How can you help them overcome this information black hole?'
        ),
      ]),
      block([
        bold('What are your legacy and philanthropy goals? '),
        text(
          'If you want your wealth to have a lasting impact or address significant issues, incorporate this into your planning. Consider the role each family member may play in this.'
        ),
      ]),
      block([
        bold('Who are the relevant business contacts? '),
        text(
          'In case of an emergency, identify the people who can support the transfer process. What are their directions, powers, and instructions?'
        ),
      ]),
      h3('Step 2: Review your assets and liabilities'),
      block([
        bold('What do you own? '),
        text(
          'To increase the likelihood of a successful transfer, compile comprehensive records of all assets and capital you own during the forward planning phase. Ensure you categorize all asset classes, including bank accounts, brokerage accounts, deposit boxes, real estate, jewelry, digital assets, stocks, bonds, public funds, gemstones, legal entities, and others.'
        ),
      ]),
      block([
        bold('What are the ownership structures? '),
        text(
          'This is crucial, especially in cases of indirect ownership structures. Consider what legal frameworks protect your family’s ownership rights in case of unforeseen events.'
        ),
      ]),
      block([
        bold('What are your liabilities? '),
        text(
          'Apply the same detailed approach to debts and obligations. These may include mortgages, secured or unsecured debts, or shared or guaranteed obligations like student loans or mortgages for children.'
        ),
      ]),
      h3('Step 3: First focus on the information, then on the documents'),
      block([
        bold('Why is information more important than documents? '),
        text(
          'Our research team interviewed over 30 trusted professionals from 7 countries on family wealth transfers. The majority (90%) concluded that for successors, claiming assets is no longer a major issue if comprehensive data is at hand. Success is a matter of following well-established routines and legal procedures.'
        ),
      ]),
      block([
        bold(
          'The heart of the problem is getting the right information at the right time '
        ),
        text(
          'about which assets to claim, their location, structure, legal status, and other specific details. While one can restore documents, nothing can be done without information about which assets the documents are missing.'
        ),
      ]),
      block([
        bold('What is the Source of a Wealth Essay (SoWE)? '),
        text(
          'In some cases, this document is known as the Source of Funds Memorandum. It is a unique document where capital founders chronicle the creation of their fortunes. The SoWE provides an opportunity to write the history of your family wealth, in your terms and words. All other documents originate from external sources, such as contracts, letters of recommendation, statements, etc. '
        ),
      ]),
      text(
        'It is recommended to prepare the SoWE in the language of the countries where your family members hold passports and residence permits. If your asset is located in a country with a different language, consider adding a certified translation of the SoWE in that language as well.'
      ),
      h3('Step 4: Set the right timing'),
      block([
        bold('What to do once information on assets has been prepared? '),
        text(
          'Review the comprehensiveness of documents and their copies for each asset. Financial institutions often require account histories, letters of recommendation, copies of money transfers, proof of ownership continuity, or other data arrays. '
        ),
      ]),
      text(
        'You can only do that efficiently on one precondition. That is, if you store all documents, even if they seem unimportant at that moment in time. These could be demanded by financial institutions at short notice. For example, the typical timeline for a bank inquiry is a few days.'
      ),
      block([
        bold('When to disclose information to family? '),
        text(
          'Timing is the key factor in the outcome of family wealth transfer. Information must be delivered precisely at the right moment to avoid two major risks. '
        ),
      ]),
      text(
        'The first is early disclosure. A family member might be tempted by the prospect of wealth before they are ready to handle the responsibility that comes with it. '
      ),
      text(
        'The second is the risk of losing assets. Typically, family members have a narrow window, from 3 to 6 months, to claim ownership of assets. Once this period elapses, assets are lost. The rule of thumb should be “Not sooner or later, but just-in-time”. The disclosure process must connect family members, assets, and trusted professionals. All parties involved should be equipped with sufficient data and instructions at the appropriate time.'
      ),
      h2('Four additional questions for family wealth self-review'),
      h3('Which wealth information should be at hand?'),
      text('Check if you have the following documents:'),
      list([
        text(
          'Letters of recommendation from banks, brokers, or other financial institutions. These letters should be no older than six months.'
        ),
        text(
          '1, 3, and 10-year cash flow statements for all your accounts, including lists of accounts with their exact opening dates.'
        ),
        text('Rationales for all large account receipts and expenditures.'),
        text('Details on the companies that paid your dividends.'),
        text(
          'Similar data for other large earnings, such as investments and bonds.'
        ),
        text('A regularly updated Source of Wealth Essay.'),
      ]),
      h3('Is there a way to evaluate if my document filing is adequate?'),
      text(
        'We surveyed both wealth owners and bank compliance officers to clarify their definitions of “good filing”.'
      ),
      text(
        'The question was straightforward: What is the volume of documents one must possess to confidently interact with financial institutions?'
      ),
      text(
        'The unit of measurement scale was calculated as the number of pages of text.'
      ),
      text(
        'Consult the table below to evaluate if your document filing is adequate.'
      ),
      image('/images/blog/wealth_table.png', 'Wealth Filing Benchmark Table'),
      h2('Why is SoWE of primary importance?'),
      text(
        'At the time of the research, less than 1% of U/HNWIs had elaborate Source of Wealth Essays.'
      ),
      text(
        'This 1% has documentary support that is detailed enough to substantiate a trustworthy history of the origins of the family’s fortunes.'
      ),
      text(
        'The importance of a well-crafted SoWE will only increase due to ever-evolving KYC regulations. In the first 5 to 10 years, family members will still rely on the predecessor’s SoWE to interact with financial institutions, proving the origins and ownership of the assets.'
      ),
      text(
        'To make a bold statement, how wealth owners prepare their SoWE today is how family members will pass KYC requirements tomorrow.'
      ),
      h2('Are wills of no use in family wealth transfers?'),
      text(
        'In 84% of cases, our respondents do not have wills in place for emergency capital transfers. Even if the capital founder is advancing in age, 72% still do not possess a will.'
      ),
      text(
        'For families worth up to $99 million, the contested rate for wills is 99.5% in developing markets and 78.7% in developed markets. Approximately 56% of assets and capital are at risk of legal challenges from third parties.'
      ),
      text(
        'Wills are important, but only to a certain extent. In the modern world, detailed data is more crucial. Without detailed data to claim the asset, the will won’t assist in the transfer.'
      ),
      text(
        'Professionals point out the problem of executing what is stated in the will, especially if the will needs to be executed across multiple jurisdictions. A safer option might be to make a separate “regional” will for some of your assets, though this is exceedingly rare.'
      ),
      h2('CONCLUSION'),
      text(
        'Data on wealth transfers outcomes run counter to the common misconceptions of wealth owners and their families. They expect the process to be straightforward. They also underestimate how data-demanding is wealth transfer.'
      ),
      text(
        'These misconceptions lead to high failure rates. Typically, $340,000 per $1,000,000 is lost in the process of transmitting wealth from the founder to the next generation.'
      ),
      listNumeric([
        text(
          'Affluent families account for 75% of total losses in both emergency and planned intergenerational transfers.'
        ),
        text(
          'In 98% of cases, wealth-related documents or wills are outdated or non-existent.'
        ),
        text(
          'Fully 97% of wealth owners opt for unreliable methods to store information on family wealth.'
        ),
        text(
          'Only 6% of wealth owners have drafted or are in the process of drafting a plan for the transfer of wealth.'
        ),
      ]),
      text(
        'If you’re looking for more sustainable approaches to wealth transfer planning, consider consulting Owner.One’s services. It equips you with a high-end tech setup to overcome common obstacles in family wealth transfers, mentioned in the article.'
      ),
      h2('Disclaimer'),
      text(
        'The provided content is intended for informational purposes only and should not be construed as financial or investment advice. We advise all readers to conduct their own research before making any investment or financial decisions.'
      ),
      text(
        'While Owner.One endeavors to keep the information up-to-date and correct, we make no representations or warranties, express or implied, regarding the completeness, accuracy, reliability, suitability, or availability of the information for any purpose. Any reliance you place on such information is strictly at your own risk.'
      ),
    ],
  },
  {
    id: 6,
    title:
      'The importance of timely sharing wealth information with your family',
    description: '',
    contentImage: '/images/blog/sharingImportance.png',
    image: '/images/blog/sharingImportance.png',
    imageAlt: 'clock',
    slug: 'the-importance-of-timely-sharing-wealth-information-with-your-family',
    meta: {
      title:
        'The importance of timely sharing wealth information with your family',
      desription: '',
      keywords: '',
    },
    outdated: true,
    content: [
      h2('Why Do Families Like Yours Lose Up to 91%?'),
      text(
        'We surveyed 13 500 high-nets across 18 countries. Their net worths range from $3 million to $100 million. They account for 75% of the total losses during family wealth transfers.'
      ),
      text(
        'The major losses occur when the capital founder steps back. Average declines are around 34% but can spike to 72%-91% for certain asset classes.'
      ),
      h2("Understanding What Are 'Data Gaps in Wealth Information'"),
      text(
        "Only the capital founders know the in-depth details of a family's wealth: asset classes, jurisdictions, agreements, and other vital attributes. All that keeps the business running."
      ),
      text(
        'The absence of any such attribute can stall or completely halt the transfer process. On average, each asset requires up to 22 attributes to complete the task. In total, 31% of family wealth is lost during transfer due to gaps in data. '
      ),
      h2('The Risks of Poor Record-Keeping'),
      text(
        'Annually, the capital founder loses up to 1/6 of their wealth information. Most of it is difficult or impossible to restore. Chances for the family are even slimmer.'
      ),
      text(
        'In 81% of cases, owners handle record-keeping by themselves. These are their preferences: '
      ),
      list([
        text('A box with documents - 25%'),
        text('A spreadsheet or a list on paper - 24%'),
        text('Haphazardly (randomly, ad hoc) - 18%'),
      ]),
      text(
        'These choices serve as the roots of gaps in the family’s asset and capital information.'
      ),
      h2("Why Trusted Professionals Won't Do the Job for You and Your Family"),
      text(
        'However, 5.3% choose to hire professionals to store information and, in case of emergency, pass it to the family. Their reasoning follows the idea that trusted individuals will handle the task better.'
      ),
      text(
        "Statistics do not support this belief. Once the family wealth transfer begins, 31.7% of attorneys fail to execute the capital founder's initial instructions, either partially or not at all."
      ),
      text(
        'Moreover, the high costs associated with third-party services, which can reach up to $180,000 annually, further complicate matters.'
      ),
      h2('What Is Information Asymmetry?'),
      text(
        'Imagine your family wealth as an iceberg. Family members see only what’s above the surface. 90% of its true structure is hidden. This mirrors the issue of information asymmetry when family members don’t know the real structure of a family’s wealth.'
      ),
      text(
        "Typically they will have a 3-6 month window to claim assets in most countries. Without full attributes, family and loved ones could reach only the 'low-hanging fruits', if anything at all. "
      ),
      h2(
        'Just-In-Time Concept: Not Too Early, Not Too Late, But Precisely At The Right Moment'
      ),
      text(
        'The outcome of the transfer depends not only on detailed information at hand. Wrong timing can spoil it as well.'
      ),
      text(
        'If a family receives it too early, they may be unprepared to handle it responsibly. Conversely, delays lead to assets becoming inaccessible or lost.'
      ),
      text(
        'Setting the right time can prevent internal conflicts, loss of motivation, and difficulties in asserting rights to assets and capital.'
      ),
      h2('How to Fix the Family Wealth Transfer'),
      h3('STEP 1: Pass the Reality Check'),
      text(
        'Overly optimistic assumptions on wealth data integrity may be misleading. Keep in mind that 7 out of 10 family wealth transfers are not successful.'
      ),

      h3('STEP 2: Whip Wealth Data into Shape'),
      text(
        '97% of wealth owners opt for unreliable tools and methods to store and update their wealth information.'
      ),
      text(
        'Assess your wealth structure, its vital attributes, and the time intervals needed to keep it up-to-date. Note that It will take around a year to clean the house if no automation is applied.'
      ),
      h3('STEP 3: Don’t Trust Anyone, Except Yourself'),
      text(
        'Attorneys are 77.6% more likely to display unscrupulous behavior towards successors compared to capital founders. Unsurprisingly, 63% of capital owners don’t trust professionals.'
      ),
      text(
        'Don’t overestimate their capabilities to achieve your long-term family goals.'
      ),
      h3('STEP 4: Seek Expert-Level Information'),
      text(
        "Discussing family wealth openly is often considered taboo due to its sensitive nature, as it merges personal and financial matters. Additionally, there's a lack of relevant data for benchmarking oneself against individuals in similar social circles."
      ),
      block([
        text('If this resonates with you, '),
        link('consider reading our report', 'https://owner.one/analytics/'),
        text(', which compiles responses from 13 500 high-nets.'),
      ]),
      h3('STEP 5: Set the Right Timing'),
      text(
        "Perhaps 'affluenza' isn't a scientifically proven term, but it sets the right framework. Every family member might be tempted by the prospect of wealth before they're ready to handle the responsibility that comes with it."
      ),
      text(
        'Avoid setting conditions for it. Transfer information at the right moment, even though it may be challenging without using digital services for automation of the task.'
      ),
      h3('Final Conclusions'),
      text(
        'We analyzed 240 apps and programs used by family offices, lawyers, and consultants within the wealth industry.'
      ),
      text(
        "Half of them are marketing apps aimed at directing customers to offline upsells. The remaining are software applications for supporting family offices' client operations.  "
      ),
      text(
        'However, wealth owners do seek technology-driven setups for their assets and money. 71% are open to using third-party digital services that operate autonomously without human intervention — a capability not yet offered by institutions marketing themselves as family wealth solution providers.'
      ),
    ],
  },
];

export const VISIBLE_BLOG_POSTS = BLOG_POSTS.filter((item) => !item.outdated);
