'use client';
import {
  memo as Memo,
  useEffect,
  useCallback,
  useState,
  ReactNode,
  CSSProperties,
} from 'react';
import cn from 'classnames';
//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Component's
import Icon from '@/components/global/Icon';

//* Style
import css from './style.module.scss';
import Text from '../Text';

const Popup = Memo<{
  closePopup: () => void;
  popupIsOpen: boolean;
  popupComponent: ReactNode;
  popupProps: {
    title?: string;
    className?: string;
    hideBackdrop?: boolean;
    position?: number[];
    bottom?: boolean;
  };
}>(({ closePopup, popupIsOpen, popupComponent, popupProps }) => {
  const [fixKb, setFixKb] = useState(false);

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);

    if ('virtualKeyboard' in navigator) {
      (
        navigator.virtualKeyboard as { overlaysContent: boolean }
      ).overlaysContent = true;
    }

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const handleKeydown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closePopup();
    }
  }, []);

  useEffect(() => {
    const fn = () => {
      if (!window.visualViewport) return;
      setFixKb(window.visualViewport.height < window.innerHeight);
    };
    window.visualViewport?.addEventListener('resize', fn);

    return () => {
      window.visualViewport?.removeEventListener('resize', fn);
    };
  }, []);

  return popupIsOpen ? (
    <div className={cn(css.wrapper, popupProps.className)}>
      <div
        className={cn(css.popupBackground, {
          [css.hide]: popupProps.hideBackdrop,
        })}
        onClick={closePopup}
      />

      <div className={css.popupContentContainer}>
        <div
          className={cn(css.popupContentWrap, {
            [css.fixed]: popupProps.position,
            [css.fixKb]: fixKb,
            [css.bottom]: popupProps.bottom,
          })}
          style={
            popupProps.position?.length
              ? ({
                  '--posX': `${popupProps.position[0]}px`,
                  '--posY': `${popupProps.position[1]}px`,
                } as CSSProperties)
              : {}
          }
        >
          <div className={css.popupHeader}>
            <Text className={css.text}>{popupProps.title}</Text>
            <Icon name={`close`} className={css.icon} onClick={closePopup} />
          </div>

          {popupComponent}
        </div>
      </div>
    </div>
  ) : null;
});

export default withUIContext(Popup, [
  'closePopup',
  'popupIsOpen',
  'popupComponent',
  'popupProps',
]);
