'use client';

import Script from 'next/script';

const CookieHub = ({}) => {
  return (
    <>
      <Script
        strategy="lazyOnload"
        src="https://cdn.cookiehub.eu/c2/bc40d758.js"
        onLoad={() => {
          var cpm = {};
          window.cookiehub.load(cpm);
        }}
      />
      <Script strategy="lazyOnload">
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag(
                        'consent','default',{
                            'security_storage':'granted',
                            'functionality_storage':'denied',
                            'personalization_storage':'denied',
                            'ad_storage':'denied',
                            'ad_user_data':'denied',
                            'ad_personalization':'denied',
                            'analytics_storage':'denied',
                            'wait_for_update':500
                        }
                    );
                `}
      </Script>
    </>
  );
};
export default CookieHub;
