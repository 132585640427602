'use client';
import { memo, useMemo, useEffect, useRef, useCallback, FC } from 'react';
import { usePathname, useParams, useSearchParams } from 'next/navigation';
import gsap from 'gsap';
import cn from 'classnames';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import css from './style.module.scss';

//* Config
import config from '@/helpers/_config';

//* Components
import Text from '@/components/global/Text';
import BurgerMenu from './BurgerMenu';
import CustomLink from '@/components/global/CustomLink';
import Button from '@/components/global/Button';
import Image from '@/components/global/Image';
import { YNButton } from './YNButton';
import { getUtmFull } from '@/helpers';

type HeaderProps = {
  winWidth: number;
  isBurgerMenuOpen: boolean;
  toggleBurgerMenu: () => void;
  closeBurgerMenu: () => void;
};

const AppHeader: FC<HeaderProps> = memo(
  ({ winWidth, toggleBurgerMenu, isBurgerMenuOpen, closeBurgerMenu }) => {
    //! Next Navigation
    const pathname = usePathname();
    const params = useParams();
    const searchParams = useSearchParams();
    //! Refs
    const logoRef = useRef<HTMLAnchorElement>(null);
    const listItemsRef = useRef<HTMLDivElement>(null);
    const contactsWrapRef = useRef<HTMLDivElement>(null);
    const ynButtonRef = useRef<HTMLAnchorElement>(null);

    //! Checks
    const headerMenus = useMemo(
      () => Object.values(config.routes).filter((item) => item.isHeader),
      [pathname]
    );

    //! Store Header Menu
    const storeHeaderMenus = useMemo(() => {
      return headerMenus.map((item, index) => {
        return (
          <CustomLink
            className={cn(css.navigationLink, {
              [css.active]: pathname.includes(item.path),
            })}
            key={index}
            url={item.path}
          >
            <Text className={cn(css.text, 'font-avenir-regular uppercase')}>
              {item.name}
            </Text>
          </CustomLink>
        );
      });
    }, [headerMenus]);

    //! Handle Logo Click
    const handleLogoClick = useCallback(() => {
      closeBurgerMenu();
    }, []);

    useEffect(() => {
      closeBurgerMenu();
    }, [winWidth, pathname, params]);

    useEffect(() => {
      logoRef.current && gsap.to(logoRef.current, { opacity: 1, delay: 0.5 });
      listItemsRef.current &&
        gsap.to(listItemsRef.current, { opacity: 1, delay: 0.5 });
      contactsWrapRef.current &&
        gsap.to(contactsWrapRef.current, { opacity: 1, delay: 0.5 });
      ynButtonRef.current &&
        gsap.to(ynButtonRef.current, { opacity: 1, delay: 0.5 });
    }, [winWidth]);

    return (
      <>
        <BurgerMenu
          headerMenus={headerMenus}
          isBurgerMenuOpen={isBurgerMenuOpen}
        />

        <div
          className={cn(css.wrapper, css.dark, 'header', {
            [css.burgerOpen]: isBurgerMenuOpen,
          })}
        >
          <div
            className={cn(css.burgerWrap, { [css.active]: isBurgerMenuOpen })}
            onClick={() => toggleBurgerMenu()}
          >
            <span className={cn(css.line, css.line1)} />
            <span className={cn(css.line, css.line2)} />
          </div>
          <a
            ref={logoRef}
            href="/"
            onClick={handleLogoClick}
            className={cn(css.logoLink, { [css.mobile]: winWidth < 1280 })}
          >
            <Image className={css.logo} src={'/images/new-logo.svg'} />
          </a>
          <div className={css.menusWrap} ref={listItemsRef}>
            {storeHeaderMenus}
          </div>
          <YNButton
            isActive={pathname.includes('/yansnotes')}
            ref={ynButtonRef}
          />
          <div className={css.contactsWrap} ref={contactsWrapRef}>
            <div className={css.buttonsWrap}>
              <Button
                external
                url={`https://personal-account.owner.one/en/signin/`}
                variant="secondary"
                className={css.joinWaitList}
              >
                CLIENTS ONLY
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default withUIContext<HeaderProps>(AppHeader, [
  'winWidth',
  'toggleBurgerMenu',
  'isBurgerMenuOpen',
  'closeBurgerMenu',
  'openPopup',
]);
