import { forwardRef, MouseEventHandler } from 'react';
import Image from 'next/image';
import cn from 'classnames';

//* Style
import css from './style.module.scss';

type CustomImageProps = {
  id?: string;
  src?: string;
  alt?: string;
  className?: string;
  width?: number;
  height?: number;
  priority?: boolean;
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
};

const CustomImage = forwardRef<HTMLImageElement, CustomImageProps>(
  (
    {
      src,
      alt,
      className,
      width,
      height,
      priority = false,
      onClick,
      id,
      onMouseEnter,
      onMouseLeave,
    },
    ref
  ) => {
    return (
      <div
        id={id}
        ref={ref}
        style={{ width, height }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cn(css.wrapper, 'image-cont', className)}
      >
        <Image
          fill
          onClick={onClick}
          priority={priority}
          alt={alt || 'Image'}
          sizes="(min-width: 320px) 100%"
          src={src || '/images/default.webp'}
          loading={priority ? 'eager' : 'lazy'}
          unoptimized={src?.includes('.svg')}
        />
      </div>
    );
  }
);

CustomImage.displayName = 'CustomImage';

export default CustomImage;
