import { appConfig } from '@/app/appConfig';

export const DOCUMENTS = {
  analytics: {
    en: {
      id: 0,
      title: 'Penguin analytics Eng',
      url: `${appConfig.serverUrlStorage}/penguin_report_en.pdf`,
    },
    es: {
      id: 1,
      title: 'Penguin analytics Esp',
      url: `${appConfig.serverUrlStorage}/penguin_report_es.pdf`,
    },
    ru: {
      id: 2,
      title: 'Penguin analytics Rus',
      url: `${appConfig.serverUrlStorage}/penguin_analytics_ru.pdf`,
    },
    ar: {
      id: 3,
      title: 'Penguin analytics Arabic',
      url: `${appConfig.serverUrlStorage}/penguin_report_ar.pdf`,
    },
    my: {
      id: 4,
      title: 'Penguin analytics Malay',
      url: `${appConfig.serverUrlStorage}/penguin_report_mal.pdf`,
    },
  },
  shortcuts: {
    en: {
      id: 5,
      title: 'Penguin Shortcuts ENG',
      url: `${appConfig.serverUrlStorage}/shortcuts_en.pdf`,
    },
    es: {
      id: 6,
      title: 'Penguin Shortcuts ESP',
      url: `${appConfig.serverUrlStorage}/shortcuts_es.pdf`,
    },
    ru: {
      id: 7,
      title: 'Penguin Shortcuts RUS',
      url: `${appConfig.serverUrlStorage}/shortcuts_ru.pdf`,
    },
    ar: {
      id: 8,
      title: 'Penguin Shortcuts ARABIC',
      url: `${appConfig.serverUrlStorage}/shortcuts_ar.pdf`,
    },
    my: {
      id: 9,
      title: 'Penguin Shortcuts MALAY',
      url: `${appConfig.serverUrlStorage}/shortcuts_mal.pdf`,
    },
  },
  beforeNow: {
    en: {
      id: 10,
      title: 'Before Now',
      url: `${appConfig.serverUrlStorage}/before_now_new.pdf`,
    },
  },
  familyGuide: {
    en: {
      id: 11,
      title: 'FAMILY GUIDE Weathering the storm',
      url: `${appConfig.serverUrlStorage}/FAMILY-GUIDE-Weathering-the-storm.pdf`,
    },
  },
  assetsDataRepository: {
    en: {
      id: 12,
      title: 'Assets Data Repository and Wealth Transfer',
      url: `${appConfig.serverUrlStorage}/Assets-Data-Repository-and-Wealth-Transfer.pdf`,
    },
  },
};

export type DocumentsType = typeof DOCUMENTS;
