import { useState, useRef, useEffect, FC, RefObject } from 'react';
import { useTranslations } from 'next-intl';
import cn from 'classnames';
import Container from '@/components/global/Container';
import { HX } from '@/components/global/HX';
import Text from '@/components/global/Text';
import css from './style.module.scss';

type HowRepositoryWorkProps = {
  containerRef: RefObject<HTMLDivElement | null>;
};

export const HowRepositoryWorks: FC<HowRepositoryWorkProps> = ({
  containerRef,
}) => {
  const t = useTranslations('homePage');

  const [active, setActive] = useState(true);

  const screenIdx = useRef(0);
  const scrolling = useRef(false);

  const timeout = (v: number) => {
    setTimeout(() => {
      screenIdx.current = v;
      scrolling.current = false;
    }, 300);
  };

  const scorllDown = () => {
    if (!containerRef.current) return;
    window.scrollTo({
      top: containerRef.current?.getBoundingClientRect().height,
      behavior: 'smooth',
    });
    setActive(false);
    timeout(1);
  };

  const scorllUp = () => {
    if (!containerRef.current) return;
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    timeout(0);
  };

  useEffect(() => {
    if (!containerRef.current) return;

    const fn = (e: WheelEvent) => {
      if (matchMedia('(max-height: 570px) or (max-width: 900px)').matches)
        return;
      if (scrolling.current) e.preventDefault();
      const bottom = containerRef.current?.getBoundingClientRect().bottom || 0;
      let canScrollUp = bottom >= 0;

      if (screenIdx.current === 0) {
        if (e.deltaY > 0) {
          e.preventDefault();
          scorllDown();
          scrolling.current = true;
        }
        return;
      }
      if (screenIdx.current === 1) {
        if (e.deltaY < 0 && canScrollUp) {
          e.preventDefault();
          scorllUp();
          scrolling.current = true;
        }
        return;
      }
      e.preventDefault();
    };
    addEventListener('wheel', fn, { passive: false });

    return () => {
      removeEventListener('wheel', fn);
    };
  }, [containerRef, screenIdx, scrolling]);

  const list = new Array(7).fill(0).map((_, i) => ({
    title: t(`list.${i}.title`),
    text: t(`list.${i}.text`),
  }));

  return (
    <div className={cn(css.hiw, { [css.active]: active })}>
      <div
        className={css.label}
        onClick={() => {
          setActive(false);
          scorllDown();
        }}
      >
        <img
          className={css.arrowIcon}
          src="/images/svg/double-arrow-down.svg"
        />
      </div>
      <Container className={css.hiwContainer}>
        <HX level="h2" className={css.hiwTitle}>
          {t('howDepoWorks')}
        </HX>
        <div className={css.hiwContentWrapper}>
          <div className={css.listWrapper}>
            <div className={css.hiwImageWrapper}>
              <img
                src="/images/home/chair.png"
                alt="yan in the chair"
                className={css.hiwImage}
              />
            </div>
            <ol className={css.list}>
              {list.map((item, i) => (
                <li className={css.listItem} key={item.title}>
                  <div className={css.numWrapper}>
                    <div className={css.numText}>{i + 1}</div>
                  </div>
                  <div>
                    <Text className={css.listTitle}>{item.title}</Text>
                    <Text className={css.listText}>{item.text}</Text>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </Container>
    </div>
  );
};
