import { forwardRef, PropsWithChildren, CSSProperties } from 'react';
import cn from 'classnames';

//* Style
import css from './style.module.scss';

type ContainerProps = PropsWithChildren & {
  row?: boolean;
  full?: boolean;
  className?: string;
  maxWidth?: number;
};

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ row, full, className, children, maxWidth }, ref) => {
    return (
      <section
        ref={ref}
        style={
          {
            '--maxWidth': maxWidth ? `${maxWidth}px` : '1360px',
          } as CSSProperties
        }
        className={cn(css.container, className, {
          [css.full]: full,
        })}
      >
        {row ? <div className={css.row}>{children}</div> : children}
      </section>
    );
  }
);

Container.displayName = 'Container';

export default Container;
